import {ADD_SELECTEDENTERPRISETERM, REMOVE_SELECTEDENTERPRISETERM, UPDATE_SELECTEDENTERPRISETERMS} from 'constants/types';

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SELECTEDENTERPRISETERM:
      return action.payload;
    case REMOVE_SELECTEDENTERPRISETERM:
      return action.payload;
    case UPDATE_SELECTEDENTERPRISETERMS:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
