// Dependencies
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// DIBK Design
import { Header } from "dibk-design";

// Components
import ActivitySearch from "components/partials/ActivitySearch";
import ProcessCategorySection from "components/partials/Facets/ProcessCategorySection";
import FacetField from "components/partials/Facets/FacetField";
import ValidDate from "components/partials/Facets/ValidDate";

// Actions
import { fetchFacets } from "actions/FacetsActions";
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

// Stylesheets
import style from "./Facets.module.scss";

function Facets() {
    const dispatch = useDispatch();

    // Redux store
    const facets = useSelector((state) => state.facets);

    // State
    const [isExpanded, setIsExpanded] = useState();

    useEffect(() => {
        dispatch(fetchFacets(true));
    }, [dispatch]);

    function renderUserFeedbackEmailAddress() {
        return getEnvironmentVariable("userFeedbackEmail");
    }
    function renderFilterSections(facets) {
        const hasFacets = facets && Object.keys(facets).length;
        return hasFacets
            ? Object.keys(facets).map((filterSectionKey) => {
                  const facetField = facets[filterSectionKey];
                  return <FacetField facetField={facetField} key={filterSectionKey} />;
              })
            : null;
    }

    function renderUserFeedback() {
        return (
            <div className={style.infoBox}>
                <button
                    type="button"
                    className={style.feedbackButton}
                    onClick={() => toggleInfo()}
                    aria-expanded={isExpanded}
                    aria-describedby="feedbackDescription"
                >
                    Gi tilbakemelding <FontAwesomeIcon icon={"coffee"} />
                </button>
                <span aria-live="polite" className={`${isExpanded ? style.textcontentOpen : style.textcontent}`}>
                    {isExpanded ? (
                        <React.Fragment>
                            Har du tilbakemeldinger eller forslag til forbedringer, eller har du funnet feil eller
                            mangler?{" "}
                            <a href={`mailto:${renderUserFeedbackEmailAddress()}?subject='fra listen'`}>
                                Send oss en henvendelse på e-post.
                            </a>
                        </React.Fragment>
                    ) : null}
                </span>
            </div>
        );
    }

    function toggleInfo() {
        setIsExpanded(!isExpanded);
    }
    return (
        <nav className={style.filter}>
            {getEnvironmentVariable("showUserFeedback") && (
                <div className={style.userFeedBackContainer}>{renderUserFeedback()}</div>
            )}
            <fieldset>
                {getEnvironmentVariable("showFilterHelpText") ? (
                    <legend>
                        <Header
                            content="Bruk valgene nedenfor for å justere innholdet i listen"
                            size={3}
                            htmlTag="h2"
                        />
                    </legend>
                ) : null}
                <ActivitySearch
                    searchableProperties={["referenceId", "name", "nameNynorsk", "description", "descriptionNynorsk"]}
                />
                <ProcessCategorySection />
                {getEnvironmentVariable("hideValidDate") !== true && <ValidDate />}

                {facets && renderFilterSections(facets)}
            </fieldset>
        </nav>
    );
}

export default Facets;
