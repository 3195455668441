// Types
import { UPDATE_SEARCH_FILTERED_ACTIVITIES } from 'constants/types';

export const updateSearchFilteredActivities = (searchFilteredActivities) => dispatch => {
    return dispatch({
        type: UPDATE_SEARCH_FILTERED_ACTIVITIES,
        payload: searchFilteredActivities
    });
};

