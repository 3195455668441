export function filterActivites(activities, searchStringWords, searchableProperties) {
    let filteredActivities = [];
    if (!!activities?.length){
        activities.forEach((activity) => {
            const activityMatch = getActivityMatch(activity, searchStringWords, searchableProperties);
            if (activityMatch) {
                filteredActivities.push(activityMatch);
            }
        });
    }
    return filteredActivities;
}

export function getActivityMatch(activity, searchStringWords, searchableProperties) {
    const subActivities = !!activity.subActivities?.length
        ? filterActivites(activity.subActivities, searchStringWords, searchableProperties)
        : [];

    const searchablePropertyWithMatch = searchableProperties.filter((searchableProperty) => {
        let matchPoints = 0;
        searchStringWords.forEach((searchStringWord) => {
            const regex = new RegExp(searchStringWord, "gi");
            const propertyMatch = activity?.[searchableProperty]?.match(regex) || null;
            matchPoints += propertyMatch ? propertyMatch.length : 0;
        });
        return matchPoints;
    });

    return searchablePropertyWithMatch.length || subActivities.length
        ? {
              ...activity,
              subActivities,
              matchPoints: searchablePropertyWithMatch.length
          }
        : null;
}

export function filterActivitiesWithSearchStringWords(activities, searchStringWords, searchableProperties) {
    if (!!searchStringWords?.length && activities?.length) {
        const searchResults = activities
            .map((activityCategory) => {
                return {
                    ...activityCategory,
                    activities: filterActivites(activityCategory.activities, searchStringWords, searchableProperties)
                };
            })
            .filter((activityCategory) => {
                return activityCategory.activities?.length;
            });
        return searchResults;
    } else {
        return activities;
    }
}
