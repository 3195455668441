// Dependencies
import React from "react";

// Components
import DibkHomeContent from "./HomeContent/Dibk";
import PlanHomeContent from "./HomeContent/Plan";
import PlanbestemmelserHomeContent from "./HomeContent/Planbestemmelser";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

const HomeContent = () => {
    function getHomeContent(themeVariables) {
        switch (themeVariables.appName) {
            case "Dibk Sjekkliste":
                return (
                    <DibkHomeContent
                        headerContent={getEnvironmentVariable("Header")}
                        checklistApiUrl={checklistApiUrl}
                    />
                );
            case "Fellestjenester plan":
                return (
                    <PlanHomeContent
                        headerContent={getEnvironmentVariable("Header")}
                        checklistApiUrl={checklistApiUrl}
                    />
                );
            case "Nasjonal planbestemmelseskatalog":
            return (
                <PlanbestemmelserHomeContent
                    headerContent={getEnvironmentVariable("Header")}
                    checklistApiUrl={checklistApiUrl}
                />
            );
            default:
                return (
                    <DibkHomeContent
                        headerContent={getEnvironmentVariable("Header")}
                        checklistApiUrl={checklistApiUrl}
                    />
                );
        }
    }

    const themeVariables = getEnvironmentVariable("themeVariables");
    const checklistApiUrl = `${getEnvironmentVariable("backendHost")}/api/sjekkliste`;
    return getHomeContent(themeVariables);
};

export default HomeContent;
