// Dependencies
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as oidcReducer } from 'redux-oidc';

// Reducers
import ActionsReducer from './ActionsReducer';
import ActivityReducer from './ActivityReducer';
import CreateActivityModelReducer from './CreateActivityModelReducer';
import ActivitiesReducer from './ActivitiesReducer';
import FacetsReducer from './FacetsReducer';
import ProcessCategoriesReducer from './ProcessCategoriesReducer';
import SelectedFacetsReducer from './SelectedFacetsReducer';
import SelectedValidDateReducer from './SelectedValidDateReducer';
import SelectedProcessCategoryKeyReducer from './SelectedProcessCategoryKeyReducer';
import EnterpriseTermsReducer from './EnterpriseTermsReducer';
import SelectedEnterpriseTermsReducer from './SelectedEnterpriseTermsReducer';
import SearchFilteredActivitiesReducer from './SearchFilteredActivitiesReducer';
import AvailableLanguageReducer from 'reducers/AvailableLanguageReducer';
import SelectedLanguageReducer from 'reducers/SelectedLanguageReducer';
import MetadataTypeReducer from 'reducers/MetadataTypeReducer';
import ValidationRulesActivitiesReducer from 'reducers/ValidationRulesActivitiesReducer';
import SearchFilteredValidationRulesActivitiesReducer from 'reducers/SearchFilteredValidationRulesActivitiesReducer';
import { adminApiSlice } from 'store/slices/adminApiSlice';
import { checklistApiSlice } from 'store/slices/checklistApiSlice';

const reducers = history => combineReducers({
  router: connectRouter(history),
  oidc: oidcReducer,
  actions: ActionsReducer,
  activity: ActivityReducer,
  createActivityModel: CreateActivityModelReducer,
  activities: ActivitiesReducer,
  searchFilteredActivities: SearchFilteredActivitiesReducer,
  facets: FacetsReducer,
  processCategories: ProcessCategoriesReducer,
  selectedFacets: SelectedFacetsReducer,
  selectedValidDate: SelectedValidDateReducer,
  selectedProcessCategoryKey: SelectedProcessCategoryKeyReducer,
  enterpriseTerms: EnterpriseTermsReducer,
  selectedEnterpriseTerms: SelectedEnterpriseTermsReducer,
  availableLanguages: AvailableLanguageReducer,
  selectedLanguage: SelectedLanguageReducer,
  metadataTypes: MetadataTypeReducer,
  validationRulesActivities: ValidationRulesActivitiesReducer,
  searchFilteredValidationRulesActivities: SearchFilteredValidationRulesActivitiesReducer,
  [adminApiSlice.reducerPath]: adminApiSlice.reducer,
  [checklistApiSlice.reducerPath]: checklistApiSlice.reducer
});

export default reducers;
