// Helpers
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';

const getFilters = filtersArray => {
  const filters = {};
  filtersArray.forEach(filter => {
    const hasSubFilters = filter.narrowerdetails && filter.narrowerdetails.length > 0;
    filters[filter.codevalue] = {
      name: filter.label,
      id: filter.codevalue,
      isGroup: hasSubFilters,
      filters: hasSubFilters ? getFilters(filter.narrowerdetails) : null
    }
  });
  return filters;
}

const geonorgeEnterpriseTermsAdapter = (geonorgeEnterpriseTerms, filtertype) => {
  const hasFilters = geonorgeEnterpriseTerms && geonorgeEnterpriseTerms.containeditems && geonorgeEnterpriseTerms.containeditems.length;
  if (hasFilters) {
    const geonorgeFilters = getFilters(geonorgeEnterpriseTerms.containeditems);
    const filters = Object.keys(geonorgeFilters)?.length ? geonorgeFilters : filtertype.filters;
  
    return {...filtertype,  filters}
  } else {
    return null;
  }
}


export const fetchEnterpriseTerms = (filtertype) => dispatch => {
  const apiUrl = getEnvironmentVariable('enterpriseTermsApiUrl');
  return fetch(apiUrl).then(res => res.json()).then(geonorgeEnterpriseTerms => {
    const enterpriseTerms = geonorgeEnterpriseTermsAdapter(geonorgeEnterpriseTerms, filtertype);      
    return enterpriseTerms;
  });
};
