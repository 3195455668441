const initialState = [
    {
        key: 'nb',
        value: 'bokmål'
    },
    {
        key: 'nn',
        value: 'nynorsk'
    }
];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
