// Dependencies
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// DIBK design
import { Header, Paper, Select } from "dibk-design";

// Components
import ValidationRulesActivitiesFilter from "./ValidationRulesActivitiesFilter";
import ActivityList from "./ActivityList";

// Actions
import { fetchProcessCategories } from "actions/ProcessCategoriesActions";
import { fetchValidationRulesActivities } from "actions/ValidationRulesActions";

function ValidationRulesList() {
    const dispatch = useDispatch();
    // State
    const [selectedProcessCategoryKey, setSelectedProcessCategoryKey] = useState();
    const [isLoadingActivities, setIsLoadingActivities] = useState(false);

    // Redux store
    const processCategories = useSelector((state) => state.processCategories);
    const oidc = useSelector((state) => state.oidc);

    function getProcessCategoryOptions(processCategories) {
        const hasProcessCategories =
            processCategories && processCategories.filters && Object.keys(processCategories.filters).length;
        return hasProcessCategories
            ? Object.keys(processCategories.filters).map((processCategoryKey) => {
                  const processCategory = processCategories.filters[processCategoryKey];
                  return {
                      key: processCategory.name,
                      value: processCategoryKey
                  };
              })
            : [];
    }

    function handleValidationRulesSave(accessToken) {
        setIsLoadingActivities(true);
        const search = "";
        dispatch(fetchValidationRulesActivities(selectedProcessCategoryKey, search, accessToken)).then(() => {
            setIsLoadingActivities(false);
        });
    }

    useEffect(() => {
        dispatch(fetchProcessCategories());
    }, [dispatch]);

    useEffect(() => {
        setIsLoadingActivities(true);
        const accessToken = !!oidc?.user?.access_token?.length && oidc.user.access_token;
        const search = "";
        dispatch(fetchValidationRulesActivities(selectedProcessCategoryKey, search, accessToken)).then(() => {
            setIsLoadingActivities(false);
        });
    }, [dispatch, oidc?.user?.access_token, selectedProcessCategoryKey]);

    return (
        <div className="admin-right-content-container">
            <Paper>
            <Header>Valideringsregler</Header>
            <Select
                label="Søknadstype"
                id="selected-process-category"
                placeholder="Velg søknadstype"
                defaultValue={selectedProcessCategoryKey}
                options={getProcessCategoryOptions(processCategories)}
                onChange={(event) => setSelectedProcessCategoryKey(event.target.value)}
            />
            <ValidationRulesActivitiesFilter />
                {isLoadingActivities ? (
                    <div>Laster inn sjekkpunkt</div>
                ) : (
                    <ActivityList onValidationRulesSave={handleValidationRulesSave} />
                )}
            </Paper>
        </div>
    );
}

export default ValidationRulesList;
