import dibkLogo from 'assets/images/logos/dibk.svg';
import arbeidstilsynetLogo from 'assets/images/logos/arbeidstilsynet.svg';

let config = {};
export default config;

const replaceLogoNameWithLogoPath = logoName => {
    switch(logoName){
        case 'arbeidstilsynet':
            return arbeidstilsynetLogo
        case 'tekcheck':
            return dibkLogo
        default:
            return dibkLogo
    }
};

function load(theme) {
    return fetch('/config.json')
    .then(result => result.json())
    .then((configJson) => {
        const newconfig = theme?.length ? configJson[theme] : configJson;
        for(let prop in config) {
            delete config[prop]
        }
        for(let prop in newconfig) {
            config[prop] = newconfig[prop]
        }
        if(config.themeVariables?.logo){
            config.themeVariables.logo = replaceLogoNameWithLogoPath(config.themeVariables.logo);
        }
        return config;
    });
}
export {load}