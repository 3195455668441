// Dependencies
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

// DIBK Design
import { InputField } from "dibk-design";

// Helpers
import { getQueryStringFromFacets } from "helpers/facetFilterHelpers";
import { convertInputDateToUrlString } from "helpers/dateHelpers";

// Stylesheets
import style from "./ValidDate.module.scss";

const ValidDate = ({ searchString }) => {
    // Redux store
    const selectedFacets = useSelector((state) => state.selectedFacets);
    const selectedValidDate = useSelector((state) => state.selectedValidDate);
    const location = useSelector((state) => state.router.location);

    // State
    const [redirect, setRedirect] = useState();

    useEffect(() => {
        if (redirect) {
            setRedirect(null);
        }
    }, [redirect]);

    function getUpdateFacetQueryString(value) {
        return getQueryStringFromFacets(selectedFacets, value, searchString, {
            facetToRemove: {
                facetField: "validDate",
                facetKey: value,
                facet: null
            }
        });
    }

    function handleDateChange(value) {
        setRedirect(`${location.pathname}${getUpdateFacetQueryString(value, "validDate")}`);
    }

    return redirect ? (
        <Redirect to={redirect} />
    ) : (
        <div className={style.validDate}>
            <InputField
                onChange={(event) => handleDateChange(convertInputDateToUrlString(event.target.value))}
                key={`activityDetails-validDate`}
                id="activityDetails-validDate"
                label="Gyldig dato"
                type="date"
                defaultValue={selectedValidDate || ""}
                aria-describedby="activityDetails-validDate-description"
                defaultContent="Dato er ikke angitt"
            />
            <div id="activityDetails-validDate-description" className={style.hidden}>
                Datoformat: DD/MM/ÅÅÅÅ
            </div>
            <div className={style.descriptiondate}>
                Sjekkpunkt kan ha en gyldig til/fra dato. Velg en dato for å se hvilke sjekkpunkt som var gyldige på den
                aktuelle datoen "
            </div>
        </div>
    );
};

export default ValidDate;
