// Dependencies
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// DIBK Design
import { Header, Button, Lead } from "dibk-design";

// Helpers
import { classNameArrayToClassNameString } from "helpers/guiHelpers";

// Stylesheets
import wizardStyle from "components/routes/Wizard.module.scss";

const PlanbestemmelserHomeContent = ({ headerContent, checklistApiUrl }) => {
    const listStyle = {
        listStyleType: "none",
        padding: "0",
    };

    return (
        <Fragment>
            <Header label="Veiviser" size={1}>
                {headerContent}
            </Header>
            <p>
                <b>Inneholder kvalitetssjekkede og anbefalte reguleringsplanbestemmelser.</b>
            </p>
            <p>
                Den nasjonale planbestemmelseskatalogen viser anbefalt struktur og skrivemåte på
                reguleringsplanbestemmelser for å få disse til å fungere i digitale arealplanprosesser. Katalogen
                inneholder forslag til lovlige, enkle og tydelige planbestemmelser som anbefales brukt av Kommunal- og
                distriktsdepartementet så langt som mulig. Det anbefales at katalogen brukes når nye reguleringsplaner
                skal lages. Departementet har ikke noe ansvar for hvordan katalogen brukes i digitale program.
            </p>

            <Header content="Inndeling i kategorier for reguleringsplanbestemmelser" size={2} />

            <ul style={listStyle}>
                <li>
                    <strong>Kategori 1:</strong> Bebyggelse og anlegg
                </li>
                <li>
                    <strong>Kategori 2:</strong> Samferdselsanlegg og teknisk infrastruktur
                </li>
                <li>
                    <strong>Kategori 3:</strong> Grønnstruktur
                </li>
                <li>
                    <strong>Kategori 4:</strong> Forsvaret
                </li>
                <li>
                    <strong>Kategori 5:</strong> Landbruks-, natur- og friluftsformål, samt reindrift, samlet eller hver
                    for seg
                </li>
                <li>
                    <strong>Kategori 6:</strong> Bruk og vern av sjø og vassdrag, med tilhørende strandsone
                </li>
            </ul>

            <div className={classNameArrayToClassNameString([wizardStyle.buttonRow, wizardStyle.marginBottom])}>
                <Button type="button" color="primary">
                    <Link to="/checklist">Velg kategori</Link>
                </Button>
            </div>

            <p>
                Planbestemmelseskatalogen vil sammen med veilederen til{" "}
                <a href="https://www.regjeringen.no/no/dokumenter/reguleringsplan/id2928063/">reguleringsplan</a> og
                veilederen til forskrift om kart, stedfestet informasjon, arealformål og digitalt planregister gi
                informasjon om hvordan reguleringsplaner kan utarbeides etter bestemmelsene i plan- og bygningsloven.
            </p>
            <p>
                Katalogen er en første utgave med reguleringsplanbestemmelser, og framover vil departementet utvikle
                katalogen videre. Vi håper den tas raskt i bruk, og for at katalogen skal bli best mulig vil vi gjerne
                ha innspill og tilbakemeldinger både til innhold, oppsett og søkemuligheter.
            </p>

            <Lead>
                <Header content="Reguleringsplanbestemmelsene kan også brukes via vårt API" size={3} />
                <a href={checklistApiUrl}>Api for reguleringsplanbestemmelser</a>
            </Lead>
        </Fragment>
    );
};

export default PlanbestemmelserHomeContent;
