const compareFacetsByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export const getOrderedFacetsByNameArray = (facets => {
  return Object.keys(facets).map(facetKey => {
    return {
      ...facets[facetKey],
      facetKey
    };
  }).sort(compareFacetsByName);
});


export const getParentFacets = (facet, parents = []) => {
  const hasParent = facet.parent && facet.parent.length;
  if (hasParent) {
    parents.push(facet.parent);
    if (getParentFacets(facet.parent)) {
      getParentFacets(facet.parent, parents);
    }
  } else {
    return false;
  }
  return parents.reverse();
}


const addFacetQueryStringRecursively = (queryStringFromFacets, facet, facetFieldKey, facetKey, facetIdToRemove) => {
  if (facet.id === facetIdToRemove) {
    return queryStringFromFacets
  }

  const childFacets = facet?.facets && Object.keys(facet.facets).length ? facet.facets : null;
  if (childFacets) {
    Object.keys(childFacets).forEach(childFacetKey => {
      const childFacet = {
        ...childFacets[childFacetKey],
        parent: facetKey
      };
      const newFacetKey = childFacetKey
      queryStringFromFacets = addFacetQueryStringRecursively(queryStringFromFacets, childFacet, facetFieldKey, newFacetKey, facetIdToRemove);
    })
  }

  const parentFacets = getParentFacets(facet);
  facetKey = parentFacets ? `${parentFacets.join('|')}|${facet.id}` : facet.id;

  const querySeparator = queryStringFromFacets ? '&' : '?';
  const queryKey = facet.isGroup ? `g_${facetFieldKey}` : facetFieldKey;
  const queryValue = facetKey;

  return queryStringFromFacets += `${querySeparator}${queryKey}=${queryValue}`;
}

export const getQueryStringFromFacets = (selectedFacets = {}, selectedValidDate, searchString, options = {}) => {
  let queryStringFromFacets = searchString
    ? `?text=${searchString}`
    : '';
  if (selectedValidDate) {
    queryStringFromFacets += queryStringFromFacets.length ? `&validDate=${selectedValidDate}` : `?validDate=${selectedValidDate}`;
  }

  if (Object.keys(selectedFacets).length) {
    Object.keys(selectedFacets).forEach(facetFieldKey => {
      const facetField = selectedFacets[facetFieldKey];
      if (facetField.facets) {
        Object.keys(facetField.facets).forEach(facetKey => {
          const facet = facetField.facets[facetKey];
          const facetShouldBeRemoved = options?.facetToRemove?.facet?.id === facet.id;
          if (!facetShouldBeRemoved) {
            queryStringFromFacets = addFacetQueryStringRecursively(queryStringFromFacets, facet, facetFieldKey, facetKey, options?.facetToRemove?.facet?.id)
          }
        })
      }
    });
  }

  if (options.facetToAdd) {
    queryStringFromFacets = addFacetQueryStringRecursively(queryStringFromFacets, options.facetToAdd.facet, options.facetToAdd.facetField, options.facetToAdd.id)
  }
  return queryStringFromFacets;
};

export function convertSearchStringToWords(searchString) {
  searchString = searchString.replace(/[^a-å0-9-., ]+/gi, ""); // Removes unwanted characters
  searchString = searchString.replace(/\s\s+/g, " "); // Remove redundant whitespace
  return searchString?.length
      ? searchString.split(" ").filter((searchStringWord) => {
            return searchStringWord.length;
        })
      : [];
}