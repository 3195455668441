// Dependencies
import React from "react";
import { Helmet } from "react-helmet";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers";

// Favicons
import dibkFavicon from "assets/favicons/dibk.ico";
import arbeidstilsynetFavicon from "assets/favicons/arbeidstilsynet.ico";

const FaviconLoader = () => {
    const themeVariables = getEnvironmentVariable("themeVariables");

    function getFavIcon() {
        switch (themeVariables.appName) {
            case "Dibk Sjekkliste":
            case "Fellestjenester plan":
            case "Nasjonal planbestemmelseskatalog":
                return dibkFavicon;
            case "Arbeidstilsynet":
                return arbeidstilsynetFavicon;
            default:
                return dibkFavicon;
        }
    }

    return (
        <Helmet>
            <link rel="icon" href={getFavIcon()}></link>
        </Helmet>
    );
};

export default FaviconLoader;
