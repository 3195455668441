// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

// Types
import { FETCH_VALIDATION_RULES_ACTIVITIES } from "constants/types";

export const fetchValidationRulesActivities = (processCategory, accessToken) => (dispatch) => {
    if (!processCategory?.length) {
        return dispatch({
            type: FETCH_VALIDATION_RULES_ACTIVITIES,
            payload: null
        });
    }
    const apiUrl = `${getEnvironmentVariable(
        "backendHost"
    )}/api/admin/activities/actions?processCategory=${processCategory}`;
    const bearerToken = `Bearer ${accessToken}`;
    const fetchOptions = {
        headers: {
            Authorization: bearerToken
        }
    };
    return fetch(apiUrl, fetchOptions)
        .then((res) => res.json())
        .then((activities) =>
            dispatch({
                type: FETCH_VALIDATION_RULES_ACTIVITIES,
                payload: activities
            })
        );
};

export const postValidationRules = (validationRules, accessToken) => (dispatch) => {
    const apiUrl = `${getEnvironmentVariable("backendHost")}/api/sjekkliste/valideringsregler`;
    const bearerToken = `Bearer ${accessToken}`;
    try {
        return fetch(apiUrl, {
            method: "POST",
            body: JSON.stringify(validationRules),
            headers: {
                "Content-Type": "application/json",
                Authorization: bearerToken
            }
        });
    } catch (error) {
        console.error("Error:", error);
    }
};

export const deleteValidationRules = (actionValidationRuleId, accessToken) => (dispatch) => {
    const apiUrl = `${getEnvironmentVariable(
        "backendHost"
    )}/api/sjekkliste/valideringsregler?actionValidationRuleId=${actionValidationRuleId}`;
    const bearerToken = `Bearer ${accessToken}`;
    try {
        return fetch(apiUrl, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: bearerToken
            }
        });
    } catch (error) {
        console.error("Error:", error);
    }
};
