// Helpers
import {getEnvironmentVariable} from 'helpers/environmentVariableHelpers.js';

// Types
import { FETCH_ACTIVITIES } from 'constants/types';

export const fetchActivities = (processCategory, urlParameterString = '', accessToken) => dispatch => {
  if (!processCategory?.length) {
    return dispatch({
      type: FETCH_ACTIVITIES,
      payload: null
    })
  }

  const parameters = new URLSearchParams(urlParameterString);
  const apiUrlParameters = [];

  for (let parameter of parameters) {
    const queryKey = parameter[0];
    const queryValue = parameter[1];
    
    const isChildFacet = queryValue.includes('|');
    const isFacetGroup = queryKey.startsWith('g_');

    if (isFacetGroup) {
      // facetGroups is not included as API URL parameter
    } else if (isChildFacet) {
      const lastHierarchySeparatorPos = queryValue.lastIndexOf('|');
      const childFacetQueryValue = queryValue.substring(lastHierarchySeparatorPos + 1);
      apiUrlParameters.push(`${queryKey}=${childFacetQueryValue}`);
    } else {
      apiUrlParameters.push(`${queryKey}=${queryValue}`);
    }
  }

  const apiUrlParameterString = apiUrlParameters.length ? `?${apiUrlParameters.join('&')}` : '';
  const apiUrl = `${getEnvironmentVariable('backendHost')}/checklist-api/${processCategory}${apiUrlParameterString}`;
  const bearerToken = `Bearer ${accessToken}`;
  const fetchOptions = {
    headers: {
      'Authorization': bearerToken
    },
    credentials: "include"
  }
  return fetch(apiUrl, fetchOptions).then(res => res.json()).then(activities => dispatch({
    type: FETCH_ACTIVITIES,
    payload: activities
  }))
};
