// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";

// Components
import HomeContent from "components/partials/HomeContent";

// Actions
import { fetchProcessCategories } from "../../actions/ProcessCategoriesActions";

// DIBK Design
import { Button, Container, Paper } from "dibk-design";

// Stylesheets
import style from "./Wizard.module.scss";

class Home extends Component {
    constructor(props) {
        super(props);
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
    }

    componentDidMount() {
        this.props.fetchProcessCategories();
    }

    handleLoginClick(event) {
        event.preventDefault();
        this.props.userManager.signinRedirect({ state: { pathname: window.location.pathname } });
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.props.userManager.signoutRedirect({ id_token_hint: this.props.user.id_token });
        this.props.userManager.removeUser();
    }

    renderLoginLink() {
        return (
            <div className={style.Link}>
                <Button type="button" onClick={this.handleLoginClick}>
                    Logg inn
                </Button>
            </div>
        );
    }

    renderLogoutLink() {
        return (
            <div className={style.navigationBarButton}>
                <Button type="button" onClick={this.handleLogoutClick}>
                    Logg ut
                </Button>
            </div>
        );
    }

    render() {
        return (
            <Container maxWidth="956px">
                <Paper>
                    <div id="main-content">
                        <HomeContent />
                        <div>
                            <h3>
                                {this.props.selectedLanguage === "nn"
                                    ? "For administrator av sjekklista"
                                    : "For administrator av sjekklisten"}
                            </h3>
                        </div>
                        {this.props.user ? this.renderLogoutLink() : this.renderLoginLink()}
                    </div>
                </Paper>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    processCategories: state.processCategories,
    user: state.oidc.user,
    selectedLanguage: state.selectedLanguage
});

const mapDispatchToProps = {
    fetchProcessCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
