import { useState } from 'react';
import './ExpandButton.scss';

function ExpandButton({ defaultExpanded = false, onExpanded = () => { }, children }) {
   const [expanded, setExpanded] = useState(defaultExpanded);

   function handleExpanded(value) {
      setExpanded(value);
      onExpanded(value);
   }

   return (
      <div className={`expand-button ${expanded ? 'expanded' : ''}`} onClick={() => handleExpanded(!expanded)} role="button">{children}</div>
   );
}

export default ExpandButton;