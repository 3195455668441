// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// DIBK Design
import { Header, RadioButtonInput } from 'dibk-design';

// Helpers
import { getActivityName, getActivityDescription, getActionName, getActionDescription } from 'helpers/languageHelpers';

// Stylesheets
import style from './ActivityListItem.module.scss';

class ActivityListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      selectedActionValue: null
    }
  }

  componentDidMount() {
    const directTabableChildrenElements = this.getDirectTabableChildrenElements()
    if(!!directTabableChildrenElements?.length){
      this.makeLinksNotTabable(directTabableChildrenElements);
    }
    const hasOnlySubActivityMatch = this.hasOnlySubActivityMatch(this.props.activity)
    this.setState({
      expanded: hasOnlySubActivityMatch
    });
  }

  componentDidUpdate(prevProps) {
    const matchPointsHasChanged = this.props.activity?.matchPoints !== prevProps.activity?.matchPoints;
    const subActivitiesHasChanged = this.props.activity?.subActivities?.length !== prevProps.activity?.subActivities?.length;
    if (matchPointsHasChanged || subActivitiesHasChanged) {
      const hasOnlySubActivityMatch = this.hasOnlySubActivityMatch(this.props.activity)
      if (hasOnlySubActivityMatch !== this.state.expanded) {
        this.setState({
          expanded: hasOnlySubActivityMatch
        });
      }
    }
  }

  radioButtonClick(value) {
    this.setState({ selectedActionValue: value })
 
  }

  toggleExpandClick() {
    this.setState({
      expanded: !this.state.expanded
    }, () => {
      const linkElements = this.getDirectTabableChildrenElements();
      this.state.expanded && !!linkElements?.length
        ? this.makeLinksTabable(linkElements)
        : this.makeLinksNotTabable(linkElements);
    })
  }

  getActivity() {
    const hasDraftActivity = this.props.activity.draftActivity && this.props.activity.draftActivity.id && this.props.activity.draftActivity.id !== 0; // TODO: Remove {Id: 0} from empty draft activities
    const isAuthenticated = this.props.oidc && this.props.oidc.user;
    return hasDraftActivity && isAuthenticated
      ? this.props.activity.draftActivity
      : this.props.activity;
  }

  getDirectTabableChildrenElements() {
    const activity = this.getActivity();
    const hasSubActivities = !!activity?.subActivities?.length;
    const subActivitiesContainerId = `activity-${activity.id}-subItems`;
    return hasSubActivities ? this.subActivitiesContainer?.querySelectorAll(`#${subActivitiesContainerId} a, #${subActivitiesContainerId} button`): null;
  }

  getHierarchyLevelIndent() {
    return !isNaN(this.props.hierarchyLevel) ? this.props.hierarchyLevel * 20 : 0;
  }

  renderActionsList(actions, selectedActionValue) {
    const actionsWithSelectedActionValue = actions.filter(action => {
      return action.actionValue === selectedActionValue;
    });
    return actionsWithSelectedActionValue.map(action => {
      return (<div key={action.id} aria-live="polite" className={style.action}>
        {action.actionType}

        <div className={style.actiondescr}> {action.descriptionNb ? <label>Forslag til svartekst: </label> : ''}<h2>{getActionName(action, this.props.selectedLanguage)}</h2>{getActionDescription(action, this.props.selectedLanguage)}</div>

      </div>);
    });
  }


  renderActivityType(activityTypeKey) {
    const availableActivityTypes = Array.isArray(this.props.facets) && this.props.facets.length ? this.props.facets.find(facet => {
      return facet.modelName === 'ActivityType' && facet.filters && Object.keys(facet.filters)?.length;
    }) : null;
    return availableActivityTypes?.filters?.[activityTypeKey]?.name || activityTypeKey
  }

  renderActivityMilestone(milestoneKey) {
    const availableMilestones = Array.isArray(this.props.facets) && this.props.facets.length ? this.props.facets.find(facet => {
      return facet.modelName === 'Milestone' && facet.filters && Object.keys(facet.filters)?.length;
    }) : null;
    return availableMilestones?.filters?.[milestoneKey]?.name || milestoneKey
  }

  renderActivityTypeClassName(activityType) { // TODO: find universal solution
    switch (activityType) {
      case 'Auto':
        return 'auto';
      case 'Vurdering':
        return 'vurdering';
      case 'Utsjekk':
        return 'utsjekk';
      case 'Hjelp til vurdering':
        return 'hjelp';
      default:
        return '';
    }
  }

  makeLinksNotTabable(linkElements) {
    if(!!linkElements?.length){
      for (let item of linkElements) {
        if (item.dataset.tabable) {
          item.tabIndex = -1;
        }
      }
  }
  };

  makeLinksTabable(linkElements) {
    if(!!linkElements?.length){
      for (let item of linkElements) {
        if (item.dataset.tabable) {
          item.tabIndex = 0;
        }
      }
    }
  };

  hasOnlySubActivityMatch(activity) {
    return activity.subActivities?.length && activity.matchPoints === 0 ? true : false;
  };


  render() {
    if (this.props.activity) {

      const isAuthenticated = this.props.oidc && this.props.oidc.user;
      const activity = this.getActivity();
      const hasSubActivities = activity.subActivities && activity.subActivities.length;
      const hasActions = activity.actions && activity.actions.length;
      const actionOutcomeYes = activity.actions.some(action => action.actionValue === true) ?? false;
      const actionOutcomeNo = activity.actions.some(action => action.actionValue === false) ?? false;
      const hasSelectedActionValue = this.state.selectedActionValue !== null;
      const hasOnlySubActivityMatch = this.hasOnlySubActivityMatch(activity)

      const subActivitiesContainerId = `activity-${activity.id}-subItems`;
      const activityTitleId = `activity-${activity.id}-title`;


      const expandButton = hasSubActivities
        ? (
          <button type="button" data-tabable={true} aria-expanded={this.state.expanded ? 'true' : 'false'} aria-controls={subActivitiesContainerId} aria-describedby={activityTitleId} onClick={() => this.toggleExpandClick()}>
            <FontAwesomeIcon alt={this.state.expanded ? 'Skjul underpunkter' : 'Vis underpunkter'} className={style.expandBtn} icon={this.state.expanded ? 'chevron-down' : 'chevron-right'} />
          </button>
        )
        : '';

      const subListItems = hasSubActivities
        ? activity.subActivities.map(subActivity => {
          return (<ActivityListItem facets={this.props.facets} activity={subActivity} parentExpanded={this.state.expanded} hierarchyLevel={this.props.hierarchyLevel + 1} selectedActivity={this.props.selectedActivity} location={this.props.location} oidc={this.props.oidc} key={subActivity.id} />)
        })
        : null;

      const radioButtonsElement = hasActions
        ? (
            <fieldset className="flex">
              <legend className={style.srOnly} >{getActivityName(activity, this.props.selectedLanguage)}</legend>
              <RadioButtonInput tabIndex={this.props.parentExpanded ? false : '-1'} id={`rbn-Ja-${activity.id}`} name={`rbn-${activity.id}`} inputValue="Ja" onChange={() => this.radioButtonClick(true)} className={style.radioButton} checked={this.state.selectedActionValue === true} expandable={actionOutcomeYes} > 
              <span>Ja</span>
              </RadioButtonInput>
              <RadioButtonInput tabIndex={this.props.parentExpanded ? false : '-1'} id={`rbn-Nei-${activity.id}`} name={`rbn-${activity.id}`} inputValue="Nei" onChange={() => this.radioButtonClick(false)} className={style.radioButton} checked={this.state.selectedActionValue === false} expandable={actionOutcomeNo}> 
              <span>Nei</span>
              </RadioButtonInput>
            </fieldset>
        ) : '';

      const processCategoryKey = typeof(activity?.processCategory) === 'string' ? activity.processCategory.toLowerCase() : "";

      return (<React.Fragment>
        <div className={`${style.listItem} ${this.props.parentExpanded ? style.isVisible : ''} ${hasSelectedActionValue ? style.hasSelectedActionValue : ''}`}>

          <div className={style.listContent} style={{ marginLeft: `${this.getHierarchyLevelIndent()}px` }}>
            <div className={`${style.expandBtnContainer} ${activity.status === 'Draft' ? style.draft : ''}`}>
              {expandButton}
            </div>
            <div className={`${style.content} ${hasOnlySubActivityMatch ? style.hasOnlySubActivityMatch : ''}`}>
              <div className={style.listItemName}>
                <Link id={activityTitleId} data-tabable={this.props.parentExpanded} to={`/checklist/${processCategoryKey}/${activity.id}${this.props.location && this.props.location.search ? this.props.location.search : ''}`}>
                  <Header size={3}>{activity.referenceId} - {getActivityName(activity, this.props.selectedLanguage)}</Header>
                </Link>
                {activity.status === 'Draft' ? ' (Kladd)' : ''}
                {hasOnlySubActivityMatch ? (<FontAwesomeIcon icon='indent' title="Har søketreff i underpunkt" className={style.matchPointsSearchIcon} />) : ''}
              </div>

              {
                isAuthenticated || getActivityDescription(activity, this.props.selectedLanguage)?.length
                  ? (
                    <div className={style.listItemDescription} tabIndex="-1">
                      {getActivityDescription(activity, this.props.selectedLanguage)}
                    </div>
                  )
                  : ''
              }

              {
                isAuthenticated || activity?.activityType?.length
                  ? (
                    <div className={style.listItemAttributes}>
                      Type sjekk: <span>{this.renderActivityType(activity.activityType)}</span> <FontAwesomeIcon icon='check-square' className={`${style.activityType} ${style[this.renderActivityTypeClassName(activity.activityType)]}`} />
                    </div>
                  )
                  : ''
              }


              {
                isAuthenticated || activity?.milestone?.length
                  ? (
                    <div className={style.listItemAttributes}>
                      Milepel: <span>{this.renderActivityMilestone(activity.milestone)}</span>
                    </div>
                  )
                  : ''
              }


                {radioButtonsElement}
              {this.renderActionsList(activity.actions, this.state.selectedActionValue)}
              {
                activity.status === "Draft" && this.props.activity.draftActivity
                  ? (<div className={style.listItemInfo}>Sjekkpunkt som er kladd og tidligere har vært publisert vil ikke kunne vise underpunkt. Disse vil vises når sjekkpunktet blir publisert.</div>)
                  : ''
              }
              {isAuthenticated
                ? (
                  <div className={style.smallBtnContainer}><Link title="Opprett nytt undersjekkpunkt" className={style.btnAdd} to={{
                    pathname: "/create-activity",
                    state: { parentReferenceId: this.props.activity.referenceId }
                  }}>+</Link></div>
                ) : ''
              }
            </div>
          </div>
        </div>
        <div id={subActivitiesContainerId} ref={subActivitiesContainer => { this.subActivitiesContainer = subActivitiesContainer }}>
          {subListItems}
        </div>
      </React.Fragment >);
    } else {
      return '';
    }
  }
}

ActivityListItem.propTypes = {
  activity: PropTypes.object.isRequired,
  parentExpanded: PropTypes.bool,
  hierarchyLevel: PropTypes.number
};

ActivityListItem.defaultProps = {
  parentExpanded: true,
  hierarchyLevel: 0
}

const mapStateToProps = state => (
  {
    oidc: state.oidc,
    selectedActivity: state.activity,
    location: state.router.location,
    selectedLanguage: state.selectedLanguage,
    facets: state.facets
  }
);

export default connect(mapStateToProps, null)(ActivityListItem);
