import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers';

export const checklistApiSlice = createApi({
   reducerPath: 'checklistApi',
   baseQuery: fetchBaseQuery({ baseUrl: '' }),
   endpoints: builder => ({
      getStaticMetadata: builder.query({
         query: () => getApiUrl('static-metadata-filters')
      }),
      getDynamicMetadata: builder.query({
         query: () => getApiUrl('metadataTypes')
      })
   })
});

function getApiUrl(endpoint) {
   return `${getEnvironmentVariable('backendHost')}/checklist-api/${endpoint}`;
}

export const { useGetStaticMetadataQuery, useGetDynamicMetadataQuery } = checklistApiSlice;