export const getActivityName = (activity, language) => {
    const defaultName = activity.name;
    switch (language) {
        case 'nn':
            return activity.nameNynorsk ? activity.nameNynorsk : defaultName
        case 'nb':
            return activity.name ? activity.name : defaultName
        default:
            return defaultName
    }
}

export const getActivityDescription = (activity, language) => {
    const defaultDescription = activity.description;
    switch (language) {
        case 'nn':
            return activity.descriptionNynorsk ? activity.descriptionNynorsk : defaultDescription
        case 'nb':
            return activity.description ? activity.description : defaultDescription
        default:
            return defaultDescription
    }
}

export const getActionName = (action, language) => {
    const defaultName = action.titleNb;
    switch (language) {
        case 'nn':
            return action.titleNn ? action.titleNn : defaultName
        case 'nb':
            return action.titleNb ? action.titleNb : defaultName
        default:
            return action.titleNb ? action.titleNb : defaultName
    }
}
export const getActionDescription = (action, language) => {
    const defaultName = action.descriptionNb;
    switch (language) {
        case 'nn':
            return action.descriptionNn ? action.descriptionNn : defaultName
        case 'nb':
            return action.descriptionNb ? action.descriptionNb : defaultName
        default:
            return action.descriptionNb ? action.descriptionNb : defaultName
    }
}
