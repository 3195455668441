import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers';

export const adminApiSlice = createApi({
   reducerPath: 'adminApi',
   baseQuery: fetchBaseQuery({
      baseUrl: '',
      prepareHeaders: async (headers, { getState }) => {
         const token = getState().oidc.user?.access_token;

         if (token) {
            headers.set('authorization', `Bearer ${token}`);
         }

         return headers;
      }
   }),
   endpoints: builder => ({
      updateStaticMetadatas: builder.mutation({
         query: filters => {
            return {
               url: getApiUrl('staticMetadatas'),
               method: 'PUT',
               body: filters
            }
         }
      }),
      addProcessCategories: builder.mutation({
         query: processCategories => {
            return {
               url: getApiUrl('processCategories'),
               method: 'POST',
               body: processCategories
            }
         }
      }),
      updateProcessCategories: builder.mutation({
         query: processCategories => {
            return {
               url: getApiUrl('processCategories'),
               method: 'PUT',
               body: processCategories
            }
         }
      }),      
      addMilestones: builder.mutation({
         query: milestones => {
            return {
               url: getApiUrl('milestones'),
               method: 'POST',
               body: milestones
            }
         }
      }),
      updateMilestones: builder.mutation({
         query: milestones => {
            return {
               url: getApiUrl('milestones'),
               method: 'PUT',
               body: milestones
            }
         }
      }),
      addActivityTypes: builder.mutation({
         query: activityTypes => {
            return {
               url: getApiUrl('activityTypes'),
               method: 'POST',
               body: activityTypes
            }
         }
      }),
      updateActivityTypes: builder.mutation({
         query: activityTypes => {
            return {
               url: getApiUrl('activityTypes'),
               method: 'PUT',
               body: activityTypes
            }
         }
      }),      
      addCategories: builder.mutation({
         query: categories => {
            return {
               url: getApiUrl('categories'),
               method: 'POST',
               body: categories
            }
         }
      }),
      updateCategories: builder.mutation({
         query: categories => {
            return {
               url: getApiUrl('categories'),
               method: 'PUT',
               body: categories
            }
         }
      }),      
      addEnterpriseTerms: builder.mutation({
         query: enterpriseTerms => {
            return {
               url: getApiUrl('enterpriseTerms'),
               method: 'POST',
               body: enterpriseTerms
            }
         }
      }),
      updateEnterpriseTerms: builder.mutation({
         query: enterpriseTerms => {
            return {
               url: getApiUrl('enterpriseTerms'),
               method: 'PUT',
               body: enterpriseTerms
            }
         }
      }),        
      addOwners: builder.mutation({
         query: owners => {
            return {
               url: getApiUrl('owners'),
               method: 'POST',
               body: owners
            }
         }
      }),
      updateOwners: builder.mutation({
         query: owners => {
            return {
               url: getApiUrl('owners'),
               method: 'PUT',
               body: owners
            }
         }
      }),   
      deleteFacets: builder.mutation({
         query: facetIds => {
            return {
               url: getApiUrl('facets'),
               method: 'DELETE',
               body: facetIds
            }
         }
      }),   
      addMetadatas: builder.mutation({
         query: metadatas => {
            return {
               url: getApiUrl('metadatas'),
               method: 'POST',
               body: metadatas
            }
         }
      }),
      updateMetadatas: builder.mutation({
         query: metadatas => {
            return {
               url: getApiUrl('metadatas'),
               method: 'PUT',
               body: metadatas
            }
         }
      }),
      deleteMetadatas: builder.mutation({
         query: metadataIds => {
            return {
               url: getApiUrl('metadatas'),
               method: 'DELETE',
               body: metadataIds
            }
         }
      }),            
      addMetadataTypes: builder.mutation({
         query: metadataTypes => {
            return {
               url: getApiUrl('metadataTypes'),
               method: 'POST',
               body: metadataTypes
            }
         }
      }),
      updateMetadataTypes: builder.mutation({
         query: metadataTypes => {
            return {
               url: getApiUrl('metadataTypes'),
               method: 'PUT',
               body: metadataTypes
            }
         }
      }),
      deleteMetadataTypes: builder.mutation({
         query: metadataTypes => {
            return {
               url: getApiUrl('metadataTypes'),
               method: 'DELETE',
               body: metadataTypes
            }
         }
      })             
   })
});

function getApiUrl(endpoint) {
   return `${getEnvironmentVariable('backendHost')}/api/admin/${endpoint}`;
}

export const { 
   useUpdateStaticMetadatasMutation, 
   useAddProcessCategoriesMutation,
   useUpdateProcessCategoriesMutation,
   useAddMilestonesMutation,
   useUpdateMilestonesMutation,
   useAddActivityTypesMutation,
   useUpdateActivityTypesMutation,
   useAddCategoriesMutation,
   useUpdateCategoriesMutation,
   useAddEnterpriseTermsMutation,
   useUpdateEnterpriseTermsMutation,
   useAddOwnersMutation,
   useUpdateOwnersMutation,
   useDeleteFacetsMutation,
   useAddMetadatasMutation,
   useUpdateMetadatasMutation,
   useDeleteMetadatasMutation,
   useAddMetadataTypesMutation,
   useUpdateMetadataTypesMutation,
   useDeleteMetadataTypesMutation
} = adminApiSlice;