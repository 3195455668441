// Dependencies
import React from "react";
import PropTypes from "prop-types";

// DIBK Design
import { Header } from "dibk-design";

// Components
import Facet from "./FacetField/Facet";

// Helpers
import { getOrderedFacetsByNameArray } from "helpers/facetFilterHelpers";

// Stylesheets
import style from "./FacetField.module.scss";

const FacetField = ({ facetField }) => {
    function renderFilterItems(facetField) {
        const hasFilters = facetField?.filters && Object.keys(facetField.filters).length;
        return hasFilters
            ? getOrderedFacetsByNameArray(facetField.filters).map((facet) => {
                  return <Facet facet={facet} facetKey={facet.facetKey} facetField={facetField} key={facet.facetKey} />;
              })
            : null;
    }
    const shouldRenderFacetField = renderFilterItems(facetField)?.length > 0 && facetField.showAsFacetFilter !== false;
    return shouldRenderFacetField ? (
        <fieldset className={style.filterSection}>
            <legend>
                <Header content={facetField.name} size={3} />
            </legend>
            <div className={style.facetFilterItemsContainer}>{renderFilterItems(facetField)}</div>
        </fieldset>
    ) : null;
};

FacetField.propTypes = {
    facetField: PropTypes.object.isRequired
};

export default FacetField;
