// Helpers
import {getEnvironmentVariable} from 'helpers/environmentVariableHelpers.js';

// Types
import { FETCH_METADATATYPES } from 'constants/types';

export const fetchMetadataTypes = () => dispatch => {
  const apiUrl = `${getEnvironmentVariable('backendHost')}/checklist-api/metadataTypes`;
  return fetch(apiUrl).then(res => res.json()).then(metadataTypes => dispatch({
    type: FETCH_METADATATYPES,
    payload: metadataTypes
  }))
};


