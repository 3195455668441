import { UPDATE_SELECTED_VALID_DATE_FROM_URL } from 'constants/types';

export const updateSelectedValidDateFromUrl = () => dispatch => {
  const urlQueryArray = decodeURI(window.location.search).replace('?', '').split('&');
  let validDate = null;

  urlQueryArray.filter(dateQuery => {
    return dateQuery && dateQuery.length;
  }).forEach(dateRangeQuery => {
    let queryArray = dateRangeQuery.split('=');
    if (queryArray.length === 2) {
      let validDateKey = queryArray[0];
      let validDateValue = queryArray[1];
      if (validDateKey === 'validDate') {
        validDate = validDateValue;
      }
    } else {
      console.warn("queryArray should have 2 values", queryArray);
    }
  });
  return dispatch({type: UPDATE_SELECTED_VALID_DATE_FROM_URL, payload: validDate});
};
