// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// DIBK Design
import { Header, List } from "dibk-design";

// components
import EnterpriseTerm from "components/partials/EnterpriseTerms/EnterpriseTerm";

class EnterpriseTerms extends Component {
    renderEnterpriseTerms(enterpriseTerms) {
        const enterpriseTermsElements = enterpriseTerms.length
            ? enterpriseTerms.map((enterpriseTerm) => {
                  return enterpriseTerm.isGroup ? (
                      enterpriseTerm.filters?.length ? (
                          <div key={enterpriseTerm.value}>
                              <Header size={5} htmlTag="h4">
                                  {enterpriseTerm.key}
                              </Header>
                              <EnterpriseTerms
                                  editable={this.props.editable}
                                  enterpriseTerms={enterpriseTerm.filters}
                              />
                          </div>
                      ) : (
                          ""
                      )
                  ) : (
                      <EnterpriseTerm
                          key={enterpriseTerm.value}
                          enterpriseTermName={enterpriseTerm.key}
                          enterpriseTermCode={enterpriseTerm.value}
                          editable={this.props.editable}
                      />
                  );
              })
            : "";
        return !!enterpriseTermsElements.length ? (
            <List listStyle={this.props.editable ? "none" : "disc"}>{enterpriseTermsElements}</List>
        ) : null;
    }
    render() {
        return this.props.enterpriseTerms ? this.renderEnterpriseTerms(this.props.enterpriseTerms) : "";
    }
}

EnterpriseTerms.propTypes = {
    editable: PropTypes.bool,
    enterpriseTerms: PropTypes.arrayOf(PropTypes.object)
};

EnterpriseTerms.defaultProps = {
    editable: false
};

export default connect(null, null)(EnterpriseTerms);
