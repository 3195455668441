// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";

// DIBK Design
import { Button, NavigationBar } from "dibk-design";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";
import { renderUserFeedbackHtml } from "helpers/userFeedbackHelpers";

// Actions
import { updateSelectedLanguage } from "actions/SelectedLanguageActions";

// Stylesheets
import style from "./MainNavigationBar.module.scss";
import { capitalizeFirstLetter } from "helpers/dataHelpers";

class MainNavigationBar extends Component {
  renderLanguageButtons(availableLanguages, selectedLanguage) {
    return availableLanguages && availableLanguages.length && selectedLanguage
      ? availableLanguages
          .filter((availableLanguage) => {
            return availableLanguage.key !== selectedLanguage;
          })
          .map((availableLanguage) => {
            return (
              <Button
                key={`language-button-${availableLanguage.key}`}
                color="primary"
                noMargin
                aria-label={`Velg om du vil se sjekkpunktene på ${availableLanguage.value}`}
                onClick={() => {
                  this.props.updateSelectedLanguage(availableLanguage.key);
                }}
              >
                <span className={style.textSmallScreen}>{capitalizeFirstLetter(availableLanguage.value)}</span>
                <span className={style.textLargeScreen}>{"Bytt til " + availableLanguage.value}</span>
              </Button>
            );
          })
      : null;
  }

  render() {
    const homelink = getEnvironmentVariable("homelink");
    const themeVariables = getEnvironmentVariable("themeVariables");
    const supportsNynorsk = getEnvironmentVariable("supportsNynorsk");
    const userFeedbackLabel = getEnvironmentVariable("userFeedbackLabel", {
      mailToAddress: getEnvironmentVariable("userFeedbackEmail"),
    });
    const userFeedbackHtml = renderUserFeedbackHtml(userFeedbackLabel, true);
    return (
      <NavigationBar
        logoLink={`${homelink}`}
        logoLinkTitle="Gå til første side av veiviseren"
        theme={themeVariables}
        mainContentId="main-content"
        preventChildElementStacking
      >
        <div className={style.rightContainer}>
          <div className={style.navigationBarText}>
            {userFeedbackHtml?.length ? <span>{userFeedbackHtml}</span> : null}
          </div>
          {supportsNynorsk
            ? this.renderLanguageButtons(this.props.availableLanguages, this.props.selectedLanguage)
            : null}
        </div>
      </NavigationBar>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.oidc.user,
  availableLanguages: state.availableLanguages,
  selectedLanguage: state.selectedLanguage,
});

const mapDispatchToProps = {
  updateSelectedLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainNavigationBar);
