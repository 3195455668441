// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import { fetchProcessCategories } from "actions/ProcessCategoriesActions";

// DIBK Design
import { Button, Container, Paper, RadioButtonList, RadioButtonListItem } from "dibk-design";

// Stylesheets
import style from "./Wizard.module.scss";

class SelectProcessCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProcessCategoryKey: null
        };
    }

    componentDidMount() {
        this.props.fetchProcessCategories();
    }

    handleOnSelectedProcessCategoryKeyChange(selectedProcessCategoryKey) {
        this.setState({
            selectedProcessCategoryKey
        });
    }

    renderProcessCategoryLinks(processCategories) {
        const hasProcessCategories =
            processCategories && processCategories.filters && Object.keys(processCategories.filters).length;
        return hasProcessCategories
            ? Object.keys(processCategories.filters).map((processCategoryKey) => {
                  const processCategory = processCategories.filters[processCategoryKey];
                  return (
                      <Link to={`/checklist/${processCategoryKey}`} key={processCategoryKey} className={style.listItem}>
                          {processCategory.name}
                      </Link>
                  );
              })
            : "";
    }
    renderProcessCategoryRadioButtonList(processCategories) {
        const hasProcessCategories = processCategories?.filters && Object.keys(processCategories.filters)?.length;
        if (hasProcessCategories) {
            const legend =
                this.props.selectedLanguage === "nn"
                    ? `Vel ${
                          processCategories?.name?.length ? processCategories.name.toLowerCase() : "søknadstype"
                      } for å sjå aktuelle sjekkpunkt`
                    : `Velg ${
                          processCategories?.name?.length ? processCategories.name.toLowerCase() : "søknadstype"
                      } for å se aktuelle sjekkpunkter`;
            const listElements = Object.keys(processCategories.filters).map((processCategoryKey) => {
                const processCategory = processCategories.filters[processCategoryKey];
                return (
                    <RadioButtonListItem
                        id={`processCategory-${processCategoryKey}`}
                        name="processCategory"
                        onChange={() => this.handleOnSelectedProcessCategoryKeyChange(processCategoryKey)}
                        inputValue={processCategoryKey}
                        key={processCategoryKey}
                        checked={this.state.selectedProcessCategoryKey === processCategoryKey}
                    >
                        {processCategory.name}
                    </RadioButtonListItem>
                );
            });
            return (
                <RadioButtonList legend={legend} legendSize={1}>
                    {listElements}
                </RadioButtonList>
            );
        }
    }
    render() {
        return (
            <Container maxWidth="956px">
                <Paper>
                    <div id="main-content">
                        {!!this.props.processCategories &&
                            this.renderProcessCategoryRadioButtonList(this.props.processCategories)}
                        <div className={style.buttonRow}>
                            <Button type="button" color="primary" arrow="left">
                                <Link to="/">Forrige</Link>
                            </Button>
                            <Button
                                color="primary"
                                type="button"
                                arrow="right"
                                disabled={!this.state.selectedProcessCategoryKey}
                            >
                                <Link
                                    to={
                                        this.state.selectedProcessCategoryKey
                                            ? `/checklist/${this.state.selectedProcessCategoryKey}`
                                            : ""
                                    }
                                >
                                    Neste
                                </Link>
                            </Button>
                        </div>
                    </div>
                </Paper>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    processCategories: state.processCategories,
    selectedLanguage: state.selectedLanguage
});

const mapDispatchToProps = {
    fetchProcessCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectProcessCategory);
