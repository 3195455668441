import { useEffect, useState } from 'react';

function useAuthentication(userManager) {
   const [isAuthenticated, setIsAuthenticated] = useState(false);

   useEffect(
      () => {
         userManager.getUser().then(user => {
            if (user === null) {
               userManager.signinRedirect({ 'state': { pathname: window.location.pathname } });
            } else {
               setIsAuthenticated(true);
            }
         })
      },
      [userManager]
   );

   return isAuthenticated;
}

export default useAuthentication;