import React, { useState } from "react";
import { Container, Header } from "dibk-design";
import { Switch, Route, NavLink, Redirect, useRouteMatch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  StaticMetadataList,
  DynamicMetadataList,
  DynamicMetadataValuesList,
  StaticMetadataValuesList,
  ValidationRulesList,
} from "components/partials/Admin";
import useAuthentication from "hooks/useAuthentication";
import "react-toastify/dist/ReactToastify.css";
import "./Admin.scss";

function Admin({ userManager }) {
  const { path, url } = useRouteMatch();
  const isAuthenticated = useAuthentication(userManager);
  const [menuExpanded, setMenuExpanded] = useState(true);

  return isAuthenticated ? (
    <Container maxWidth="1320px">
      <div className="admin">
        <button className="expandButton" onClick={() => setMenuExpanded(!menuExpanded) }>
          {menuExpanded ? "Skjul filter" : "Vis filter"}
        </button>
        <div className={`admin-left-content${menuExpanded ? " expanded" : ""}`}>
          <div className="menu-section">
            <Header content="Administrasjon" size={3} htmlTag="h2" />
            <ul className="admin-menu">
              <li>
                <NavLink to={`${url}/static-metadata`}>Faste metadata</NavLink>
              </li>
              <li>
                <NavLink to={`${url}/static-metadata-values`}>Faste metadataverdier</NavLink>
              </li>
              <li>
                <NavLink to={`${url}/dynamic-metadata`}>Dynamiske metadata</NavLink>
              </li>
              <li>
                <NavLink to={`${url}/dynamic-metadata-values`}>Dynamiske metadataverdier</NavLink>
              </li>
              <li>
                <NavLink to={`${url}/validation-rules`}>Valideringsregler</NavLink>
              </li>
            </ul>
          </div>
        </div>

        <div className="admin-right-content">
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${url}/static-metadata`} />
            </Route>
            <Route path={`${path}/static-metadata`}>
              <StaticMetadataList />
            </Route>
            <Route path={`${path}/static-metadata-values`}>
              <StaticMetadataValuesList />
            </Route>
            <Route path={`${path}/dynamic-metadata`}>
              <DynamicMetadataList />
            </Route>
            <Route path={`${path}/dynamic-metadata-values`}>
              <DynamicMetadataValuesList />
            </Route>
            <Route path={`${path}/validation-rules`}>
              <ValidationRulesList />
            </Route>
          </Switch>
        </div>

        <ToastContainer />
      </div>
    </Container>
  ) : null;
}

export default Admin;
