// Helpers
import {getEnvironmentVariable} from 'helpers/environmentVariableHelpers.js';

// Types
import {FETCH_ACTIVITY, FETCH_ACTIVITY_MODEL} from 'constants/types';


export const fetchCreateActivityModel = (accessToken, parentReferenceId) => dispatch => {
  let apiUrl = `${getEnvironmentVariable('backendHost')}/checklist-api/draft`;
  const parentReferenceIdUrlParam = parentReferenceId ? new URLSearchParams({
    parentReferenceId
  }) : "";
  if (parentReferenceIdUrlParam) {
    apiUrl += `?${parentReferenceIdUrlParam.toString()}`;
  }
  const bearerToken = `Bearer ${accessToken}`;
  return fetch(apiUrl, {
    headers: {
     'Content-Type': 'application/json',
     'Authorization': bearerToken
   },
   credentials: "include"
  }).then(
    res => res.json()).then(
      activity => dispatch(
    {type: FETCH_ACTIVITY_MODEL, payload: activity}
  ))
};

export const createNewActivity = (activity, accessToken) => dispatch => {
  const apiUrl = `${getEnvironmentVariable('backendHost')}/checklist-api/draft`;
  const bearerToken = `Bearer ${accessToken}`;
  try {
    return fetch(apiUrl, {
      method: 'POST',
      body: JSON.stringify(activity),
      headers: {
       'Content-Type': 'application/json',
       'Authorization': bearerToken
     },
     credentials: "include"
    }).then(res => res.json()).then(activity => {
      return activity
    });
  } catch (error) {
    console.error('Error:', error);
  }
};

export const fetchActivity = activityId => dispatch => {
  const apiUrl = `${getEnvironmentVariable('backendHost')}/checklist-api/detail/${activityId}`;
  return fetch(apiUrl).then(res => res.json()).then(activity => dispatch({type: FETCH_ACTIVITY, payload: activity}))
};

export const createDraftActivity = (activityId, accessToken) => (dispatch, getState) => {
    const apiUrl = `${getEnvironmentVariable('backendHost')}/checklist-api/draft/${activityId}`;
    const bearerToken = `Bearer ${accessToken}`;
    try {
      return fetch(apiUrl, {
        method: 'POST',
        headers: {
         'Content-Type': 'application/json',
         'Authorization': bearerToken
       },
       credentials: "include"
      }).then(res => res.json()).then(activity => {
        return activity
      });
    } catch (error) {
      console.error('Error:', error);
    }

};

export const updateDraftActivity = (activity, accessToken) => (dispatch, getState) => {
    const apiUrl = `${getEnvironmentVariable('backendHost')}/checklist-api/draft/${activity.id}`;
    const bearerToken = `Bearer ${accessToken}`;
    try {
      return fetch(apiUrl, {
        method: 'PUT',
        body: JSON.stringify(activity),
        headers: {
         'Content-Type': 'application/json',
         'Authorization': bearerToken
       },
       credentials: "include"
      }).then(res => res.json()).then(activity => {
        return activity
      });
    } catch (error) {
      console.error('Error:', error);
    }
};

export const updatePublishedActivity = (draftActivity, accessToken) => (dispatch, getState) => {
    const apiUrl = `${getEnvironmentVariable('backendHost')}/checklist-api/activity/${draftActivity.id}`;
    const bearerToken = `Bearer ${accessToken}`;
    try {
      return fetch(apiUrl, {
        method: 'PUT',
        body: JSON.stringify(draftActivity),
        headers: {
         'Content-Type': 'application/json',
         'Authorization': bearerToken
       },
       credentials: "include"
      }).then(res => res.json()).then(activity => {
        return activity
      });
    } catch (error) {
      console.error('Error:', error);
    }
};
