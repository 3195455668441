// Dependencies
import { Fragment } from "react";

// Componets
import ActionList from "./ActivityListItem/ActionList";

// Stylesheets
import style from "./ActivityListItem.module.scss";

function ActivityListItem({ activity, onValidationRulesSave }) {
    return (
        <Fragment>
            <div className={style.actionListContainer}>
                <ActionList actions={activity.actions} activityId={activity.activityId} onValidationRulesSave={onValidationRulesSave} />
            </div>
        </Fragment>
    );
}

export default ActivityListItem;
