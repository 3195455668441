// Dependencies
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// DIBK Design
import { CheckBoxInput, InputField } from "dibk-design";

// Actions
import { updateSearchFilteredValidationRulesActivities } from "actions/SearchFilteredValidationRulesActivities";

// Helpers
import { filterActivites } from "helpers/activityHelpers";
import { convertSearchStringToWords } from "helpers/facetFilterHelpers";

function ValidationRulesActivitiesFilter() {
    const dispatch = useDispatch();

    // State
    const [searchString, setSearchString] = useState("");
    const [hideActivitiesWithoutActions, setHideActivitiesWithoutActions] = useState(true);

    // Redux store
    const validationRulesActivities = useSelector((state) => state.validationRulesActivities);

    function handleSearchChange(searchString) {
        setSearchString(searchString);
    }

    function handleShowActivitiesWithActionsOnlyChange(showActivitiesWithActionsOnly) {
        setHideActivitiesWithoutActions(!showActivitiesWithActionsOnly);
    }

    useEffect(() => {
        const searchableProperties = ["activityReferenceId", "activityName"];
        const searchStringWords = convertSearchStringToWords(searchString);
        const searchResults =
            !!searchStringWords?.length && !!searchableProperties?.length
                ? filterActivites(validationRulesActivities, searchStringWords, searchableProperties)
                : validationRulesActivities;
        if (hideActivitiesWithoutActions) {
            const searchResultsWithoutActions =
                !!searchResults?.length &&
                searchResults.filter((activity) => {
                    return !!activity?.actions?.some((action) => {
                        return !!action?.supportingDataValidationRuleId?.length;
                    });
                });
            dispatch(updateSearchFilteredValidationRulesActivities(searchResultsWithoutActions));
        } else {
            dispatch(updateSearchFilteredValidationRulesActivities(searchResults));
        }
    }, [dispatch, hideActivitiesWithoutActions, searchString, validationRulesActivities]);

    return (
        <Fragment>
            <InputField
                elementKey={`activitySearch`}
                onChange={(event) => handleSearchChange(event?.target?.value)}
                id="activity-search"
                label="Søk"
                type="search"
                role="search"
                defaultValue={searchString}
                placeholder="Søk etter sjekkpunkt"
            />
            <CheckBoxInput
                type="checkbox"
                onChange={(event) => handleShowActivitiesWithActionsOnlyChange(!event.target.checked)}
                id="show-activities-with-actions-only"
                checked={hideActivitiesWithoutActions}
            >
                Vis kun sjekkpunkt med regler
            </CheckBoxInput>
        </Fragment>
    );
}

export default ValidationRulesActivitiesFilter;
