// Dependencies
import { commands } from "@uiw/react-md-editor";

export const defaultExtraCommands = [
    commands.divider,
    commands.codeEdit,
    commands.codeLive,
    commands.codePreview,
    commands.divider,
    commands.fullscreen
];

export const customExtraCommands = [
    {
        name: "squared",
        keyCommand: "squared",
        buttonProps: { "aria-label": "Insert squared symbol" },
        icon: <span>x²</span>,
        execute: (state, api) => {
            let modifyText = `${state.selectedText}²`;
            if (!state.selectedText) {
                modifyText = `²`;
            }
            api.replaceSelection(modifyText);
        }
    },
    {
        name: "cubed",
        keyCommand: "cubed",
        buttonProps: { "aria-label": "Insert cubed symbol" },
        icon: <span>x³</span>,
        execute: (state, api) => {
            let modifyText = `${state.selectedText}³`;
            if (!state.selectedText) {
                modifyText = `³`;
            }
            api.replaceSelection(modifyText);
        }
    }
];
