// Dependencies
import React from "react";

// DIBK Design
import { Footer } from "dibk-design";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers";

const FooterContainer = () => {
    const userFeedbackEmail = getEnvironmentVariable("userFeedbackEmail");

    return (
        <Footer>
            <p>
                Lurer du på hvordan kommunen kan motta eByggesøknader?
                <br />
                <a
                    href="https://dibk.no/saksbehandling-tilsyn-og-kontroll/gjor-kommunen-din-klar-for-digitale-byggesoknader"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Se veiledning på dibk.no (åpnes i nytt vindu)"
                >
                    Se veiledning på dibk.no
                </a>
            </p>
            <p>
                Har du spørsmål eller forslag til forbedringer?
                <br />
                Ta kontakt på e-post:{" "}
                <a href={`mailto:${userFeedbackEmail}`} target="_blank" rel="noopener noreferrer">
                    {userFeedbackEmail}
                </a>
            </p>
            <p>
                <a
                    href="https://uustatus.no/nb/erklaringer/publisert/31cdddae-cf23-4282-aea3-4406842c0aa4"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Tilgjengelighetserklæring (åpnes i nytt vindu)"
                >
                    Tilgjengelighetserklæring
                </a>
            </p>
        </Footer>
    );
};

export default FooterContainer;
