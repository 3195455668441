// Helpers
import {getEnvironmentVariable} from 'helpers/environmentVariableHelpers.js';

// Types
import { FETCH_ACTIONS } from 'constants/types';


const geonorgeActionsAdapter = geonorgeActions => {
  const hasActions = geonorgeActions && geonorgeActions.containeditems && geonorgeActions.containeditems.length;
  if (hasActions) {
    return geonorgeActions.containeditems.map(action => {
      return {actionTypeCode: action.codevalue, actionType: action.label};
    })
  } else {
    return null;
  }
}

export const fetchActions = () => dispatch => {
  const apiUrl = getEnvironmentVariable('actionsApiUrl');
  return fetch(apiUrl).then(res => res.json()).then(geonorgeActions => {
    const actions = geonorgeActionsAdapter(geonorgeActions);
    return actions
      ? dispatch({type: FETCH_ACTIONS, payload: actions})
      : console.warn('no actions')
  });
};
