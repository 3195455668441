export const FETCH_ACTIONS = 'FETCH_ACTIONS';
export const FETCH_ACTIVITY = 'FETCH_ACTIVITY';
export const FETCH_ACTIVITY_MODEL = 'FETCH_ACTIVITY_MODEL';
export const FETCH_CONFIG = 'FETCH_CONFIG';
export const CREATE_DRAFT_ACTIVITY = 'CREATE_DRAFT_ACTIVITY';
export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
export const FETCH_ENTERPRISETERMS = 'FETCH_ENTERPRISETERMS';
export const ADD_SELECTEDENTERPRISETERM = 'ADD_SELECTEDENTERPRISETERM';
export const FETCH_SELECTED_FACETS = 'FETCH_SELECTED_FACETS';
export const UPDATE_SELECTED_FACETS_FROM_URL = 'UPDATE_SELECTED_FACETS_FROM_URL';
export const UPDATE_SELECTED_VALID_DATE_FROM_URL = 'UPDATE_SELECTED_VALID_DATE_FROM_URL';
export const REMOVE_SELECTEDENTERPRISETERM = 'REMOVE_SELECTEDENTERPRISETERM';
export const UPDATE_SELECTEDENTERPRISETERMS = 'UPDATE_SELECTEDENTERPRISETERMS';
export const FETCH_FACETS = 'FETCH_FACETS';
export const FETCH_PROCESSCATEGORIES = 'FETCH_PROCESSCATEGORIES';
export const UPDATE_SELECTEDPROCESSCATEGORYKEY = 'UPDATE_SELECTEDPROCESSCATEGORYKEY';
export const UPDATE_SELECTED_LANGUAGE = 'UPDATE_SELECTED_LANGUAGE';
export const UPDATE_SEARCH_FILTERED_ACTIVITIES = 'UPDATE_SEARCH_FILTERED_ACTIVITIES';
export const FETCH_METADATATYPES = 'FETCH_METADATATYPES';
export const FETCH_VALIDATION_RULES_ACTIVITIES = 'FETCH_VALIDATION_RULES_ACTIVITIES';
export const UPDATE_SEARCH_FILTERED_VALIDATION_RULES_ACTIVITIES = 'UPDATE_SEARCH_FILTERED_VALIDATION_RULES_ACTIVITIES';

