import React, { Fragment, useState } from "react";
import { createRoot } from "react-dom/client";
import "index.scss";
import App from "App";
import * as serviceWorker from "serviceWorker";
import ConfigLoader from "components/ConfigLoader";
import "react-md-editor-override.css";

const Main = () => {
    const showThemeSelector = false; // Only for development purposes

    const [theme, setTheme] = useState(showThemeSelector && "dibk");

    return (
        <Fragment>
            {showThemeSelector && (
                <select onChange={(event) => setTheme(event.target.value)}>
                    <option value="dibk">DIBK</option>
                    <option value="arbeidstilsynet">Arbeidstilsynet</option>
                    <option value="fellestjenesterPlan">Fellestjenester plan</option>
                    <option value="nasjonalPlanbestemmelseskatalog">Nasjonal planbestemmelseskatalog</option>
                    <option value="trappesjekk">Trappesjekk</option>
                </select>
            )}
            <ConfigLoader theme={theme} ready={() => <App />} />
        </Fragment>
    );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Main />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
