// Helpers
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';
import { fetchEnterpriseTerms } from 'actions/EnterpriseTermsActions';

// Types
import { FETCH_FACETS, UPDATE_SELECTED_FACETS_FROM_URL } from 'constants/types';

export const fetchFacets = (showAsFacetFilter = false) => dispatch => {

  var apiUrl = `${getEnvironmentVariable('backendHost')}/checklist-api/filter-data`;
  if(showAsFacetFilter === true) apiUrl += "?showAsFacetFilter=true";
  return fetch(apiUrl).then(res => res.json()).then(facets => { 
    
    const hasGeonorgeApiEnterpriseTermsUrl = !!getEnvironmentVariable('enterpriseTermsApiUrl').match(/(https:\/\/register)*.*(geonorge.no\/api)/gm);
    if(hasGeonorgeApiEnterpriseTermsUrl){
    const enterpriseTermsFacet = facets.find(facet => {
      return facet.modelName === 'EnterpriseTerms';
    });

    facets = facets.filter(facet => { // Remove null values
      return facet !== null && facet.modelName !== 'EnterpriseTerms' // TODO: Removing old version of EnterpriseTerms until local API is updated
    });

    dispatch(fetchEnterpriseTerms(enterpriseTermsFacet)).then(enterpriseTerms => {
      facets.push(enterpriseTerms);
      facets && Object.keys(facets).length
        ? dispatch({ type: FETCH_FACETS, payload: facets })
        : console.warn('No properties in ' + process.env.REACT_APP_FILTERS_API_URI);
    })
  }
  else{
    dispatch({ type: FETCH_FACETS, payload: facets })
  }
  })
};

const findFacetInArray = (facets, facetHierarchyForFacetValueArray, index = 0) => {
  let facetValue = index < facetHierarchyForFacetValueArray.length
    ? facetHierarchyForFacetValueArray[index]
    : null;
  let childFacetValue = index < facetHierarchyForFacetValueArray.length - 1
    ? facetHierarchyForFacetValueArray[index + 1]
    : null;

  const facetKey = Object.keys(facets).find(availableFacetKey => {
    const facet = facets[availableFacetKey];
    return facet.id === facetValue;
  });

  const facetFromArray = facets[facetKey];

  if (!facetFromArray) {
    console.log("cant find facet: ", facetValue);
    return null;
  }

  const facetFilters = facetFromArray.filters;
  let facet = {
    facetKey: facetKey,
    id: facetFromArray.id,
    name: facetFromArray.name,
    isGroup: facetFromArray.isGroup ? true : false
  };
  if (childFacetValue) {
    facet.facets = {
      [childFacetValue]: findFacetInArray(facetFilters, facetHierarchyForFacetValueArray, index + 1)
    }
  }
  return facet;
};

const addChildFacets = (facets, facetToAdd) => {
  if (!facets[facetToAdd.facetKey]) {
    facets[facetToAdd.facetKey] = {
      ...facetToAdd,
      facets: {}
    };
  }
  if (facetToAdd.facets) {
    Object.keys(facetToAdd.facets).forEach(childFacetName => {
      facets[facetToAdd.facetKey].facets = addChildFacets(facets[facetToAdd.facetKey].facets, facetToAdd.facets[childFacetName]); // Recursively adding child facets to facet
    })
  }
  return facets;
};

export const updateSelectedFacetsFromUrl = (availableFacets = {}) => dispatch => {
  const urlQueryArray = decodeURI(window.location.search).replace('?', '').split('&');

  let facets = {};

  urlQueryArray.filter(facetQuery => {
    return facetQuery && facetQuery.length;
  }).forEach(facetQuery => {
    let queryArray = facetQuery.split('=');
    if (queryArray.length === 2) {
      let facetField = queryArray[0].startsWith('g_') ? queryArray[0].substring(2) : queryArray[0];
      let facetValuesForFacetField = queryArray[1];
      let facetValuesForFacetFieldArray = facetValuesForFacetField.split(',');

      if (availableFacets[facetField]) {
        facetValuesForFacetFieldArray.forEach(facetValue => {
          let facetHierarchyForFacetValueArray = facetValue.split('|');
          let facet = availableFacets[facetField]
            ? findFacetInArray(availableFacets[facetField].filters, facetHierarchyForFacetValueArray)
            : null;
          if (facet) {
            if (!facets[facetField]) { // Creating facet field e.g. Type, Theme, etc. if not created
              facets[facetField] = {
                facets: {},
                name: facetField
              };
            }
            facets[facetField].facets = addChildFacets(facets[facetField].facets, facet); // Adding child facets to facet field
          }
        })
      }
    } else {
      console.warn("queryArray should have 2 values", queryArray);
    }

  });
  return dispatch({ type: UPDATE_SELECTED_FACETS_FROM_URL, payload: facets });
};
