// Dependencies
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { OidcProvider } from "redux-oidc";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
    faCheckSquare,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCoffee,
    faEnvelope,
    faFilter,
    faIndent,
    faInfoCircle,
    faMinus,
    faPlus,
    faQuestionCircle,
    faTimes
} from "@fortawesome/free-solid-svg-icons";

// Utils
import configureStore, { history } from "utils/configureStore";
import userManagerPromise from "utils/userManager";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

// Components
import NotFound from "components/routes/NotFound";
import OidcCallback from "components/routes/OidcCallback";
import OidcSignoutCallback from "components/routes/OidcSignoutCallback";
import Home from "components/routes/Home";
import SelectProcessCategory from "components/routes/SelectProcessCategory";
import ProcessCategory from "components/routes/ProcessCategory";
import Activity from "components/routes/Activity";
import CreateActivity from "components/routes/CreateActivity";
import MainNavigationBar from "components/partials/MainNavigationBar";
import Admin from "components/routes/Admin";
import Footer from "components/partials/Footer";
import { ThemeProvider } from "dibk-design";
import FaviconLoader from "components/template/FaviconLoader";

library.add(
    fab,
    faCheckSquare,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCoffee,
    faEnvelope,
    faFilter,
    faIndent,
    faInfoCircle,
    faMinus,
    faPlus,
    faQuestionCircle,
    faTimes
);

const initialState = {};

const storePromise = configureStore(initialState, userManagerPromise);
let store = null;
let userManager = null;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storeIsLoaded: false,
            userManagerIsLoaded: false
        };
    }

    componentDidMount() {
        storePromise.then((storeConfig) => {
            store = storeConfig;

            if (!this.state.userManagerIsLoaded) {
                this.setState({
                    userManagerIsLoaded: true
                });
            }
        });

        userManagerPromise.then((userManagerConfig) => {
            userManager = userManagerConfig;

            this.setState({
                storeIsLoaded: true
            });
        });
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.props.userManager.signoutRedirect({ id_token_hint: this.props.user.id_token });
        this.props.userManager.removeUser();
    }

    render() {
        if (this.state && userManager && this.state.userManagerIsLoaded && this.state.storeIsLoaded) {
            return (
                <Provider store={store}>
                    <OidcProvider userManager={userManager} store={store}>
                        <ConnectedRouter history={history}>
                            <Helmet>
                                <title>{getEnvironmentVariable("AppTitle") ?? "Sjekkliste"}</title>
                            </Helmet>
                                <FaviconLoader />
                            <ThemeProvider theme={getEnvironmentVariable("themeVariables")}>
                                <MainNavigationBar userManager={userManager} />
                                <main>
                                    <Switch>
                                        <Route
                                            exact={true}
                                            path="/"
                                            render={() => <Home userManager={userManager} />}
                                        />
                                        <Route
                                            exact={true}
                                            path="/checklist"
                                            render={() => <SelectProcessCategory />}
                                        />
                                        <Route
                                            exact={true}
                                            path="/create-activity"
                                            render={(props) => <CreateActivity userManager={userManager} {...props} />}
                                        />
                                        <Route
                                            exact
                                            path="/signin-oidc"
                                            render={() => <OidcCallback userManager={userManager} />}
                                        />
                                        <Route
                                            exact
                                            path="/signout-callback-oidc"
                                            render={() => <OidcSignoutCallback userManager={userManager} />}
                                        />
                                        <Route
                                            exact={true}
                                            path="/Checklist/:processCategoryKey"
                                            render={(props) => <ProcessCategory {...props} />}
                                        />
                                        <Route
                                            exact={true}
                                            path="/Checklist/:processCategoryKey/:activityId"
                                            render={(props) => <ProcessCategory {...props} />}
                                        />
                                        <Route
                                            exact={true}
                                            path="/Checklist/:processCategoryKey/:activityId/details"
                                            render={(props) => <Activity {...props} />}
                                        />
                                        <Route path="/admin" render={() => <Admin userManager={userManager} />} />
                                        <Route render={() => <NotFound />} />
                                    </Switch>
                                </main>
                                <Footer />
                            </ThemeProvider>
                        </ConnectedRouter>
                    </OidcProvider>
                </Provider>
            );
        } else return "";
    }
}

export default App;
