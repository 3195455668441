// Dependencies
import React from "react";
import PropTypes from "prop-types";

// DIBK Design
import { Header } from "dibk-design";

// Components
import ActivityListItem from "./ActivitySection/ActivityListItem";

// Stylesheets
import style from "./ActivitySection.module.scss";

function ActivitySection({ activitySection }) {
    function renderActivitySections(activitySection) {
        const hasActivities = activitySection && activitySection.activities.length;
        return hasActivities
            ? activitySection.activities.map((activity) => {
                  return <ActivityListItem key={activity.id} activity={activity} />;
              })
            : null;
    }

    return (
        <section className={style.activitySection}>
            <Header content={activitySection.category} size={2} />
            {renderActivitySections(activitySection)}
        </section>
    );
}

ActivitySection.propTypes = {
    activitySection: PropTypes.object.isRequired
};

export default ActivitySection;
