import { useEffect, useState } from "react";
import { load } from "components/config";

function ConfigLoader({ loading, ready, theme }) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [config, setConfig] = useState(null);

    useEffect(() => {
        setIsLoaded(false);
        load(theme).then((config) => {
            setConfig(config);
            setIsLoaded(true);
        });
    }, [theme]);

    // If we haven't yet loaded the config, show either a "splash" component provided via a `loading` props or return nothing.
    if (!isLoaded) {
        return loading ? loading() : null;
    }

    // The config is loaded so show the component set on the `ready()` props
    return ready(config);
}

export default ConfigLoader;
