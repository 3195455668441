import { useEffect, useRef, useState } from 'react';
import { Button, InputField } from 'dibk-design';
import { ReactSortable } from 'react-sortablejs';
import { useAddMetadatasMutation, useUpdateMetadatasMutation, useDeleteMetadatasMutation } from 'store/slices/adminApiSlice';
import { createRandomId, objectHasChanged } from 'helpers/dataHelpers';
import { toast } from 'helpers/formHelpers';
import ExpandButton from 'components/custom/ExpandButton/ExpandButton';
import './DynamicMetadataValues.scss';

function DynamicMetadataValues({ metadataType }) {
   const [dynamicMetadataValues, setDynamicMetadataValues] = useState([]);
   const originalDynamicMetadataValues = useRef([]);
   const [expanded, setExpanded] = useState(false);
   const [addMetadatas] = useAddMetadatasMutation();
   const [updateMetadatas] = useUpdateMetadatasMutation();
   const [deleteMetadatas] = useDeleteMetadatasMutation();

   useEffect(
      () => {
         const mappedData = metadataType.metadataValues.map(value => ({ ...value, elementKey: createRandomId(), chosen: true }));
         setDynamicMetadataValues([...mappedData]);
         originalDynamicMetadataValues.current = [...mappedData];
      },
      [metadataType.metadataValues]
   );

   function handleChange(event, index) {
      const { name, value } = event.target;

      const updated = dynamicMetadataValues.map((filter, idx) => {
         return idx !== index ? filter : { ...filter, [name]: value };
      });

      setDynamicMetadataValues(updated);
   }

   function handleListSorted(sortedList) {
      sortedList.forEach((value, index) => value.orderNumber = index + 1);
      setDynamicMetadataValues(sortedList);
   }

   function addEntry() {
      setDynamicMetadataValues([...dynamicMetadataValues, {
         id: 0,
         typeId: metadataType.id,
         value: '',
         metadataCode: '',
         orderNumber: dynamicMetadataValues.length,
         chosen: true,
         elementKey: createRandomId()
      }]);
   }

   function deleteEntry(index) {
      setDynamicMetadataValues(dynamicMetadataValues.filter((_, idx) => idx !== index));
   }

   async function save() {
      handleListSorted(dynamicMetadataValues);

      if (!objectHasChanged(originalDynamicMetadataValues.current, dynamicMetadataValues)) {
         return;
      }

      const toAdd = dynamicMetadataValues.filter(metadataValue => metadataValue.id === 0);
      const toUpdate = dynamicMetadataValues.filter(metadataType => metadataType.id !== 0);
      const toDelete = originalDynamicMetadataValues.current
         .filter(metadataType => !dynamicMetadataValues.some(mdataType => mdataType.id === metadataType.id))
         .map(metadataType => metadataType.id);

      try {
         await apiAdd(toAdd);
         await apiUpdate(toUpdate);
         await apiDelete(toDelete);

         toast.success('Endringene ble lagret');
         originalDynamicMetadataValues.current = [...dynamicMetadataValues];
      } catch {
         toast.error('Kunne ikke lagre endringene');
      }
   }

   async function apiAdd(metadataValues) {
      if (!metadataValues.length) {
         return;
      }

      try {
         await addMetadatas(metadataValues).unwrap();
      } catch {
         throw new Error();
      }
   }

   async function apiUpdate(metadataValues) {
      if (!metadataValues.length) {
         return;
      }

      try {
         await updateMetadatas(metadataValues).unwrap();
      } catch {
         throw new Error();
      }
   }

   async function apiDelete(metadataValueIds) {
      if (!metadataValueIds.length) {
         return;
      }

      try {
         await deleteMetadatas(metadataValueIds).unwrap();
      } catch {
         throw new Error();
      }
   }

   return (
      <div className={`filter ${expanded ? 'filter-expanded' : ''}`}>
         <ExpandButton onExpanded={setExpanded}>{metadataType.name} ({dynamicMetadataValues.length})</ExpandButton>

         <div className={`dynamic-metadata-values-container ${expanded ? 'expanded' : ''}`}>
            {
               dynamicMetadataValues.length ?
                  <div>
                     <div className="dynamic-metadata-values-table">
                        <div className="dynamic-metadata-values-table-header">
                           <div></div>
                           <div>Verdi</div>
                           <div>Kode</div>
                           <div></div>
                        </div>
                        <ReactSortable list={dynamicMetadataValues} setList={handleListSorted} handle=".handle">
                           {
                              dynamicMetadataValues.map((metadataValue, index) => {
                                 return (
                                    <div className="dynamic-metadata-values-table-row" key={`facet-${metadataValue.elementKey}`}>
                                       <div>
                                          <div className="handle"></div>
                                       </div>
                                       <div>
                                          <InputField
                                             elementKey={'value-' + metadataValue.elementKey}
                                             id={'value-' + index}
                                             name="value"
                                             onChange={event => handleChange(event, index)}
                                             defaultValue={metadataValue.value || ''}
                                          />
                                       </div>
                                       <div>
                                          <InputField
                                             elementKey={'metadataCode-' + metadataValue.elementKey}
                                             id={'metadataCode-' + index}
                                             name="metadataCode"
                                             onChange={event => handleChange(event, index)}
                                             defaultValue={metadataValue.metadataCode || ''}
                                          />
                                       </div>
                                       <div>
                                          <Button color="secondary" size="small" onClick={() => deleteEntry(index)}>Slett</Button>
                                          {
                                             index === dynamicMetadataValues.length - 1 ?
                                                <Button color="primary" size="small" onClick={addEntry}>Legg til</Button> :
                                                null
                                          }
                                       </div>
                                    </div>
                                 )
                              })
                           }
                        </ReactSortable>
                     </div>
                     <div>
                     { objectHasChanged(originalDynamicMetadataValues.current, dynamicMetadataValues) ?
                        <Button color="primary" size="small" onClick={save}>Lagre</Button> 
                        : null
                        }
                     </div>
                  </div> :
                  <div>
                     <div>
                        { objectHasChanged(originalDynamicMetadataValues.current, dynamicMetadataValues) ? 
                           <Button color="primary" size="small" onClick={save}>Lagre</Button> 
                           : null
                        }
                     </div>
                  <div>
                  <Button color="primary" size="small" onClick={addEntry}>Legg til</Button>
                  </div>
                  </div>
            }
         </div>
      </div>
   );
}

export default DynamicMetadataValues;