// DIBK Design
import { Button, InputField } from "dibk-design";

// Stylesheets
import style from "../ActionListItem.module.scss";

function ValidationRuleIds({
    validationRuleId,
    validationRuleIndex,
    versionIndex,
    handleOnChangeValidationRule,
    handleRemoveValidationRule
}) {
    const inputKey = `${validationRuleId.tempId}-input`;
    return (
        <div className={style.inlineInputs}>
            <InputField
                id={inputKey}
                elementKey={inputKey}
                defaultValue={validationRuleId.value}
                label={`Valideringsregel ${validationRuleIndex + 1}`}
                onChange={(event) =>
                    handleOnChangeValidationRule(
                        { tempId: validationRuleId.tempId, value: event.target.value },
                        versionIndex,
                        validationRuleIndex
                    )
                }
            />
            <Button
                onClick={() => {
                    handleRemoveValidationRule(versionIndex, validationRuleIndex);
                }}
            >
                Slett
            </Button>
        </div>
    );
}

export default ValidationRuleIds;
