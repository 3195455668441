import {ADD_SELECTEDENTERPRISETERM, REMOVE_SELECTEDENTERPRISETERM, UPDATE_SELECTEDENTERPRISETERMS} from 'constants/types';

export const addSelectedEnterpriseTerm = enterpriseTermCode => (dispatch, getState) => {
  const selectedEnterpriseTerms = getState().selectedEnterpriseTerms;
  selectedEnterpriseTerms.push({code: enterpriseTermCode});
  return dispatch({type: ADD_SELECTEDENTERPRISETERM, payload: selectedEnterpriseTerms})
};

export const removeSelectedEnterpriseTerm = enterpriseTermCode => (dispatch, getState) => {
  const selectedEnterpriseTerms = getState().selectedEnterpriseTerms.filter(selectedEnterpriseTerm => {
    return selectedEnterpriseTerm.code !== enterpriseTermCode;
  });
  dispatch({type: REMOVE_SELECTEDENTERPRISETERM, payload: selectedEnterpriseTerms})
};

export const updateSelectedEnterpriseTerms = enterpriseTerms => dispatch => {
  dispatch({type: UPDATE_SELECTEDENTERPRISETERMS, payload: enterpriseTerms})
};
