// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";

// Actions
import { fetchFacets } from "actions/FacetsActions";
import { fetchProcessCategories } from "actions/ProcessCategoriesActions";
import { fetchCreateActivityModel, createNewActivity } from "actions/ActivityActions";
import { fetchMetadataTypes } from "actions/MetadataTypesActions";

// Template
import Modal from "components/template/Modal";

// DIBK Design
import { Button, CheckBoxList, CheckBoxListItem, Container, Header, InputField, Paper, Select } from "dibk-design";

// Helpers
import { convertFromInputDateToTimestamp, convertFromTimestampToInputDate } from "helpers/dateHelpers";
import { getOrderedFacetsByNameArray } from "helpers/facetFilterHelpers";

// Stylesheets
import style from "./CreateActivity.module.scss";

class CreateActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activity: null,
            createNewActivityResponse: null,
        };
        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    fetchCreateActivityModel = (prevProps) => {
        const hadOidcUser = prevProps && prevProps.oidc && prevProps.oidc.user && prevProps.oidc.user.access_token;
        const hasOidcUser = this.props.oidc && this.props.oidc.user && this.props.oidc.user.access_token;
        if (!hadOidcUser && hasOidcUser) {
            const accessToken = this.props.oidc && this.props.oidc.user && this.props.oidc.user.access_token;
            const parentReferenceId = this.isSubactivity(this.props.location.state)
                ? this.props.location.state.parentReferenceId
                : null;
            this.props.fetchCreateActivityModel(accessToken, parentReferenceId).then((createActivityModelResponse) => {
                this.setState({ activity: createActivityModelResponse.payload });
            });
        }
    };

    updateCreateActivityModelState = (fieldName, value) => {
        this.setState({
            activity: {
                ...this.state.activity,
                [fieldName]: value,
            },
        });
    };
    handleLoginClick(event) {
        event.preventDefault();
        this.props.userManager.signinRedirect({ state: { pathname: window.location.pathname } });
    }

    renderLoginLink() {
        return (
            <div className={style.loggbtn}>
                <Button type="button" content="Logg inn" onClick={this.handleLoginClick} />
            </div>
        );
    }
    componentDidMount() {
        document.body.classList.remove("no-scroll");
        this.fetchCreateActivityModel();
        const hasFilters = this.props.filters && this.props.filters.length;
        if (!hasFilters) {
            this.props.fetchFacets();
        }
        const hasProcessCategories = this.props.processCategories && Object.keys(this.props.processCategories).length;
        if (!hasProcessCategories) {
            this.props.fetchProcessCategories();
        }
        const hasMetadataTypes = this.props.metadataTypes && this.props.metadataTypes.length;
        if (!hasMetadataTypes) {
            this.props.fetchMetadataTypes();
        }
    }

    componentDidUpdate(prevProps) {
        this.fetchCreateActivityModel(prevProps);
    }

    isSubactivity(state) {
        return state && state.parentReferenceId && state.parentReferenceId.length ? true : false;
    }

    updateActivityStateField(fieldName, value) {
        this.setState({
            activity: {
                ...this.state.activity,
                [fieldName]: value,
            },
        });
    }

    pushToActivityStateArray(fieldName, value) {
        let activityStateArray = this.state.activity[fieldName] ? this.state.activity[fieldName] : [];
        activityStateArray.push(value);
        this.setState({
            activity: {
                ...this.state.activity,
                [fieldName]: activityStateArray,
            },
        });
    }

    removeFromActivityStateArray(fieldName, value) {
        let activityStateArray = this.state.activity[fieldName] ? this.state.activity[fieldName] : [];
        const index = activityStateArray.indexOf(value);
        if (index > -1) {
            activityStateArray.splice(index, 1);
        }
        this.setState({
            activity: {
                ...this.state.activity,
                [fieldName]: activityStateArray,
            },
        });
    }

    convertFilterTypeToOptionValues(filterType) {
        const convertedFilterType =
            filterType && filterType.filters && Object.keys(filterType.filters).length
                ? Object.keys(filterType.filters).map((filterKey) => {
                      const filter = filterType.filters[filterKey];
                      return {
                          key: filter?.name ? filter.name : filter?.id,
                          value: filter?.id,
                      };
                  })
                : [];
        return convertedFilterType;
    }

    getOptionValuesForFilterType(filters, filterModelName) {
        const hasFilters = filters && filters.length;
        if (hasFilters) {
            const filterType = filters.find((filter) => {
                return filter.modelName === filterModelName;
            });
            return this.convertFilterTypeToOptionValues(filterType);
        }
        return [];
    }

    getOptionValuesForProcessCategories(processCategories) {
        return processCategories ? this.convertFilterTypeToOptionValues(processCategories) : [];
    }

    processCategoryIsSelected(processCategory) {
        return (
            this.state.activity.processCategories &&
            this.state.activity.processCategories &&
            this.state.activity.processCategories.includes(processCategory)
        );
    }

    handleProcessCategoryRadioButtonClick(processCategory) {
        const isSelected = this.processCategoryIsSelected(processCategory);
        if (isSelected) {
            this.removeFromActivityStateArray("processCategories", processCategory);
        } else {
            this.pushToActivityStateArray("processCategories", processCategory);
        }
    }

    renderCheckboxesForProcessCategories(processCategories, isSubactivity) {
        const processCategoryOptions = this.getOptionValuesForProcessCategories(processCategories);
        return processCategoryOptions.map((processCategory) => {
            return (
                <CheckBoxListItem
                    key={processCategory.value}
                    id={`processCategory-${processCategory.value}`}
                    checked={this.processCategoryIsSelected(processCategory.value)}
                    onChange={() => this.handleProcessCategoryRadioButtonClick(processCategory.value)}
                    contentOnly={isSubactivity}
                    value={processCategory.value}
                >
                    {processCategory.key}
                </CheckBoxListItem>
            );
        });
    }

    removeLawReferenceFromList(index) {
        let lawReferences = this.state.activity.lawReferences;
        lawReferences.splice(index, 1);
        this.setState({
            activity: {
                ...this.state.activity,
                lawReferences: lawReferences,
            },
        });
    }

    addNewLawReferenceToList() {
        let lawReferences = this.state.activity.lawReferences;
        lawReferences.push({
            lawReferenceDescription: null,
            lawReferenceUrl: null,
        });
        this.setState({
            activity: {
                ...this.state.activity,
                lawReferences: lawReferences,
            },
        });
    }

    updateLawReferencesList(property, value, index) {
        let lawReferences = this.state.activity.lawReferences;
        lawReferences[index][property] = value;
        this.setState({
            activity: {
                ...this.state.activity,
                lawReferences: lawReferences,
            },
        });
    }

    renderLawReferencesList(lawReferences) {
        const lawReferencesListElements =
            lawReferences && lawReferences.length
                ? lawReferences.map((lawReference, lawReferenceIndex) => {
                      return (
                          <div key={lawReferenceIndex} className={style.inlineInputs}>
                              <InputField
                                  elementKey={`activityDetails-LawReference-description-${lawReferenceIndex}`}
                                  onChange={(event) =>
                                      this.updateLawReferencesList(
                                          "lawReferenceDescription",
                                          event.target.value,
                                          lawReferenceIndex,
                                      )
                                  }
                                  id={`activityDetails-LawReference-description-${lawReferenceIndex}`}
                                  label="Beskrivelse"
                                  value={lawReference.lawReferenceDescription}
                              />
                              <InputField
                                  elementKey={`activityDetails-LawReference-url-${lawReferenceIndex}`}
                                  onChange={(event) =>
                                      this.updateLawReferencesList(
                                          "lawReferenceUrl",
                                          event.target.value,
                                          lawReferenceIndex,
                                      )
                                  }
                                  id={`activityDetails-LawReference-url-${lawReferenceIndex}`}
                                  label="Lenke"
                                  value={lawReference.lawReferenceUrl}
                              />
                              <Button
                                  type="button"
                                  onClick={(event) => this.removeLawReferenceFromList(lawReferenceIndex)}
                                  id={`activityDetails-removeLawReference-${lawReferenceIndex}`}
                                  content="Fjern"
                              />
                          </div>
                      );
                  })
                : "";
        return (
            <React.Fragment>
                {lawReferencesListElements}
                <Button
                    type="button"
                    onClick={() => this.addNewLawReferenceToList()}
                    id="activityDetails-newLawReference"
                >
                    Legg til lovhjemmel
                </Button>
            </React.Fragment>
        );
    }

    getSelectedKeyValueObjectFromSelectElement(event) {
        const selectElement = event.target;
        const selectedIndex = selectElement.options.selectedIndex;
        const selectedOptionElement = selectElement.options[selectedIndex];
        return {
            id: selectedOptionElement.value,
            description: selectedOptionElement.innerText,
        };
    }

    renderCreatedActivitiesList(activities) {
        const activityListItems = activities?.length && activities.map((activity) => {
            const filterKeyForProcessCategory =
                Object.keys(this.props.processCategories?.filters).length &&
                Object.keys(this.props.processCategories.filters).find((filterKey) => {
                    const filter = this.props.processCategories.filters[filterKey];
                    return filter.id === activity.processCategory;
                });
            const filterForProcessCategory =
                filterKeyForProcessCategory && this.props.processCategories?.filters?.[filterKeyForProcessCategory];
            const processCategoryName = filterForProcessCategory?.name || "";
            return (
                <li>
                    <div className={style.listItemName}>
                        <Link
                            to={`/checklist/${activity.processCategory}/${activity.id}${
                                this.props.location && this.props.location.search ? this.props.location.search : ""
                            }/details`}
                        >
                            {processCategoryName} - {activity.name}
                        </Link>
                    </div>
                    <div className={style.listItemDescription}></div>
                </li>
            );
        });
        return <ul>{activityListItems}</ul>;
    }

    renderCreateNewActivityResponseModal(activities) {
        const onClickOutsideModal = () => {
            this.setState({
                createNewActivityResponse: null,
            });
        };
        const isSubactivity = this.isSubactivity(this.props.location.state);
        return (
            <Modal onClickOutside={onClickOutsideModal}>
                <div className={style.modalContentContainer}>
                    <Header
                        content={isSubactivity ? "Undersjekkpunkt er opprettet" : "Sjekkpunkt er opprettet"}
                        size={2}
                    />
                    <p>
                        Sjekkpunktet <b>{activities?.[0]?.name}</b> er opprettet med ID <b>{activities?.[0]?.referenceId}</b>{" "}
                        for {activities.length} {activities.length > 1 ? "tiltakstyper" : "tiltakstype"}.
                    </p>
                    <p>Klikk på en av søknadstypene under for å legge til tiltakstyper og resultat/utfall.</p>
                    {this.renderCreatedActivitiesList(activities)}
                </div>
            </Modal>
        );
    }

    handleFormSubmit(accessToken) {
        this.props.createNewActivity(this.state.activity, accessToken).then((createNewActivityResponse) => {
            this.setState({
                createNewActivityResponse,
            });
        });
    }

    getOptionValuesForMetadataType(metadataTypes, metadataTypeId) {
        const hasMetadataTypes = metadataTypes && metadataTypes.length;
        if (hasMetadataTypes) {
            const metadataType = metadataTypes.find((type) => {
                return type.id === metadataTypeId;
            });
            return this.convertMetadataValuesToOptionValues(metadataType);
        }
        return [];
    }

    convertMetadataValuesToOptionValues(metadataType) {
        const hasFilters = metadataType?.metadataValues && Object.keys(metadataType.metadataValues).length;
        return hasFilters
            ? getOrderedFacetsByNameArray(metadataType.metadataValues).map((metadata) => {
                  return {
                      key: metadata.value,
                      value: metadata.id.toString(),
                      isGroup: false,
                      filters: null,
                  };
              })
            : [];
    }

    updateActivityMetadataStateField(metadataTypeId, metadataValue, metadataId) {
        const activityMetadata = this.props.createActivityModel.metadata;
        const currentMetadataTypeIndex =
            activityMetadata?.findIndex((metadata) => metadata.metadataTypeId === metadataTypeId) ?? -1;

        if (currentMetadataTypeIndex > -1) {
            activityMetadata.splice(currentMetadataTypeIndex, 1);
        }

        activityMetadata.push({
            metadataId,
            metadataValue: metadataValue !== null ? metadataValue : metadataId,
            metadataTypeId,
        });

        this.setState({
            activity: {
                ...this.state.activity,
                metadata: activityMetadata,
            },
        });
    }

    handleClear(metadataTypeId) {
        metadataTypeId = parseInt(metadataTypeId);
        const activityMetadata = this.props.createActivityModel.metadata;
        const currentMetadataTypeIndex = activityMetadata.findIndex(
            (metadata) => metadata.metadataTypeId === metadataTypeId,
        );

        if (currentMetadataTypeIndex > -1) {
            activityMetadata.splice(currentMetadataTypeIndex, 1);
        }

        this.setState({
            activity: {
                ...this.state.activity,
                metadata: activityMetadata,
            },
        });
    }

    renderDynamicMetadataSelectField(metadataTypeId, metadataTypeName, label, placeholder) {
        const options = this.getOptionValuesForMetadataType(this.props.metadataTypes, metadataTypeId);
        const value = this.state.activity?.metadata?.find((m) => m.metadataTypeId === metadataTypeId) ?? "";
        return (
            <React.Fragment>
                <Select
                    id={`activityDetails-${metadataTypeId}`}
                    name={metadataTypeName}
                    label={label}
                    onChange={(event) =>
                        this.updateActivityMetadataStateField(metadataTypeId, null, event.target.value)
                    }
                    value={value?.metadataValue ?? ""}
                    options={options}
                    keyAsContent={true}
                    placeholder={placeholder || ""}
                />
            </React.Fragment>
        );
    }

    renderDynamicMetadataInputField(metadataTypeId, metadataTypeName) {
        return (
            <React.Fragment>
                <InputField
                    elementKey={`activityDetails-${metadataTypeName}-${metadataTypeId}`}
                    onChange={(event) =>
                        this.updateActivityMetadataStateField(metadataTypeId, event.target.value, null, "number")
                    }
                    id={`activityDetails-${metadataTypeName}-${metadataTypeId}`}
                    label={metadataTypeName}
                />
            </React.Fragment>
        );
    }

    renderDynamicMetadataCheckbox(metadataTypeId, metadataTypeName, label) {
        const values = this.state.activity.metadata.find((m) => m.metadataTypeId === metadataTypeId);
        const options = this.getOptionValuesForMetadataType(this.props.metadataTypes, metadataTypeId);

        return (
            <React.Fragment>
                <span>{label}</span>
                {options.map((metadata, mIndex) => {
                    const isChecked = values?.metadataId === metadata.value ?? false;
                    const clearMetadataValue = isChecked;

                    return (
                        <CheckBoxListItem
                            key={`activityDetailsCheckbox-${metadataTypeName}-${metadataTypeId}-${mIndex}`}
                            onChange={() =>
                                clearMetadataValue
                                    ? this.handleClear(metadataTypeId)
                                    : this.updateActivityMetadataStateField(
                                          metadataTypeId,
                                          null,
                                          metadata.value,
                                          "number",
                                      )
                            }
                            checked={isChecked}
                            name="checkbox"
                            id={`activityDetailsCheckbox-${metadataTypeName}-${metadataTypeId}-${mIndex}`}
                        >
                            {metadata.key}
                        </CheckBoxListItem>
                    );
                })}
            </React.Fragment>
        );
    }

    renderMetadataList(metadataTypes) {
        const commonMetadataTypes = metadataTypes?.filter((m) => m.commonValue);
        const metadataTypesElement =
            commonMetadataTypes && commonMetadataTypes.length
                ? commonMetadataTypes.map((metadataType, metadataTypeIndex) => {
                      return (
                          <div key={metadataTypeIndex}>
                              <div className={style.activityDetailsField}>
                                  {
                                      <React.Fragment>
                                          {metadataType.viewType === "Dropdownlist"
                                              ? this.renderDynamicMetadataSelectField(
                                                    metadataType.id,
                                                    metadataType.name,
                                                    metadataType.name,
                                                    "Velg fra listen",
                                                )
                                              : ""}
                                          {metadataType.viewType === "Textbox"
                                              ? this.renderDynamicMetadataInputField(
                                                    metadataType.id,
                                                    metadataType.name,
                                                    metadataType.name,
                                                )
                                              : ""}
                                          {metadataType.viewType === "Checkbox"
                                              ? this.renderDynamicMetadataCheckbox(
                                                    metadataType.id,
                                                    metadataType.name,
                                                    metadataType.name,
                                                )
                                              : ""}
                                      </React.Fragment>
                                  }
                              </div>
                          </div>
                      );
                  })
                : "";

        return <React.Fragment>{metadataTypesElement}</React.Fragment>;
    }

    render() {
        const accessToken =
            this.props.oidc && this.props.oidc.user && this.props.oidc.user.access_token
                ? this.props.oidc.user.access_token
                : null;
        const isSubactivity = this.isSubactivity(this.props.location.state);
        return accessToken && this.state.activity ? (
            <Container maxWidth="956px">
                {this.state.createNewActivityResponse
                    ? this.renderCreateNewActivityResponseModal(this.state.createNewActivityResponse)
                    : ""}
                <Paper>
                    <Header
                        content={isSubactivity ? "Opprett nytt undersjekkpunkt" : "Opprett nytt sjekkpunkt"}
                        size={2}
                    />

                    <div className={style.activityDetailsField}>
                        <Select
                            id="activityDetails-Category"
                            label={isSubactivity ? "Tema arvet fra hovedsjekkpunkt " : "Tema"}
                            onChange={(event) =>
                                this.updateActivityStateField(
                                    "category",
                                    this.getSelectedKeyValueObjectFromSelectElement(event),
                                )
                            }
                            value={this.state.activity?.category?.id || ""}
                            options={this.getOptionValuesForFilterType(this.props.filters, "Category")}
                            contentOnly={isSubactivity}
                            keyAsContent={true}
                            placeholder="Velg tema fra listen"
                        />
                    </div>

                    <div className={style.activityDetailsField}>
                        <InputField
                            elementKey={`activityDetails-name`}
                            id="activityDetails-name"
                            label={isSubactivity ? "Undersjekkpunkt" : "Sjekkpunkt"}
                            onChange={(event) => this.updateActivityStateField("name", event.target.value)}
                            value={this.state.activity?.name || ""}
                        />
                    </div>

                    <div className={style.activityDetailsField}>
                        <InputField
                            elementKey={`activityDetails-nameNynorsk`}
                            id="activityDetails-nameNynorsk"
                            label={isSubactivity ? "Undersjekkpunkt nynorsk" : "Sjekkpunkt nynorsk"}
                            onChange={(event) => this.updateActivityStateField("nameNynorsk", event.target.value)}
                            value={this.state.activity?.nameNynorsk || ""}
                        />
                    </div>

                    <div className={style.activityDetailsField}>
                        <InputField
                            elementKey={`activityDetails-description`}
                            id="activityDetails-description"
                            label="Hjelpetekst ( Frivillig )"
                            onChange={(event) => this.updateActivityStateField("description", event.target.value)}
                            value={this.state.activity?.description || ""}
                        />
                    </div>

                    <div className={style.activityDetailsField}>
                        <InputField
                            elementKey={`activityDetails-descriptionNynorsk`}
                            id="activityDetails-descriptionNynorsk"
                            label="Hjelpetekst nynorsk( Frivillig )"
                            onChange={(event) =>
                                this.updateActivityStateField("descriptionNynorsk", event.target.value)
                            }
                            value={this.state.activity?.descriptionNynorsk || ""}
                        />
                    </div>

                    {
                        <div className={style.activityDetailsField} style={{ display: "block" }}>
                            <label>Dokumentasjon</label>
                            <div data-color-mode="light">
                                <MDEditor
                                    id="activityDetails-documentation"
                                    preview="edit"
                                    height={200}
                                    name="documentation"
                                    value={this.state.activity?.documentation || ""}
                                    onChange={(value) => {
                                        this.updateActivityStateField("documentation", value);
                                    }}
                                />
                            </div>
                        </div>
                    }

                    <div className={style.activityDetailsField}>
                        <Select
                            id="activityDetails-ActivityType"
                            label="Type sjekk"
                            onChange={(event) =>
                                this.updateActivityStateField(
                                    "activityType",
                                    this.getSelectedKeyValueObjectFromSelectElement(event),
                                )
                            }
                            value={this.state.activity?.activityType?.id || ""}
                            options={this.getOptionValuesForFilterType(this.props.filters, "ActivityType")}
                            keyAsContent={true}
                            placeholder="Velg type sjekk fra listen"
                        />
                    </div>

                    <div className={style.activityDetailsField}>
                        <div className={style.inlineInputs}>
                            <InputField
                                onChange={(event) =>
                                    this.updateActivityStateField(
                                        "validFrom",
                                        convertFromInputDateToTimestamp(event.target.value),
                                    )
                                }
                                elementKey={`activityDetails-validFrom`}
                                id="activityDetails-ValidFrom"
                                label="Gyldig fra ( frivillig )"
                                type="date"
                                max={convertFromTimestampToInputDate(this.state.activity.validTo)}
                            />
                            <InputField
                                onChange={(event) =>
                                    this.updateActivityStateField(
                                        "validTo",
                                        convertFromInputDateToTimestamp(event.target.value),
                                    )
                                }
                                elementKey={`activityDetails-validTo`}
                                id="activityDetails-ValidTo"
                                label="Gyldig til ( frivillig )"
                                type="date"
                                min={convertFromTimestampToInputDate(this.state.activity.validFrom)}
                            />
                        </div>
                    </div>

                    <div className={style.activityDetailsField}>
                        <Select
                            id="activityDetails-Milestone"
                            label="Milepel"
                            onChange={(event) =>
                                this.updateActivityStateField(
                                    "milestone",
                                    this.getSelectedKeyValueObjectFromSelectElement(event),
                                )
                            }
                            value={this.state.activity?.milestone?.id || ""}
                            options={this.getOptionValuesForFilterType(this.props.filters, "Milestone")}
                            placeholder="Velg milepel fra listen"
                        />
                    </div>
                    {this.renderMetadataList(this.props.metadataTypes)}

                    <div className={style.activityDetailsField}>
                        <InputField
                            elementKey={`activityDetails-rule`}
                            onChange={(event) => this.updateActivityStateField("rule", event.target.value)}
                            id="activityDetails-Rule"
                            label="Maskinlesbar regel"
                            value={this.state.activity?.rule || ""}
                        />
                    </div>

                    <Header
                        content={`Søknadstyper for sjekkpunkt ${isSubactivity ? "(arvet fra hovedsjekkpunkt)" : ""}`}
                        size={2}
                    />
                    <div className={style.activityDetailsField}>
                        <CheckBoxList>
                            {this.renderCheckboxesForProcessCategories(this.props.processCategories, isSubactivity)}
                        </CheckBoxList>
                    </div>

                    <Header
                        content={isSubactivity ? "Lovhjemler for undersjekkpunkt" : "Lovhjemler for sjekkpunkt"}
                        size={2}
                    />
                    <div className={style.activityDetailsField}>
                        {this.renderLawReferencesList(this.state.activity.lawReferences)}
                    </div>

                    <div className={style.buttonRow}>
                        <Button type="button" onClick={() => this.handleFormSubmit(accessToken)} color="primary">
                            {isSubactivity ? "Opprett undersjekkpunkt" : "Opprett sjekkpunkt"}
                        </Button>
                    </div>
                </Paper>
            </Container>
        ) : (
            <Container maxWidth="956px">
                <Paper>
                    <Header
                        content="Du må logge inn for å opprette et nytt sjekkpunkt/undersjekkpunkt"
                        size={2}
                        htmlTag="h1"
                    />
                    <div className={style.activityDetailsField}>{this.renderLoginLink()}</div>
                </Paper>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    processCategories: state.processCategories,
    createActivityModel: state.createActivityModel,
    filters: state.facets,
    location: state.router.location,
    oidc: state.oidc,
    user: state.oidc.user,
    metadataTypes: state.metadataTypes,
});

const mapDispatchToProps = {
    fetchFacets,
    fetchProcessCategories,
    fetchCreateActivityModel,
    createNewActivity,
    fetchMetadataTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateActivity);
