import { UPDATE_SEARCH_FILTERED_ACTIVITIES } from 'constants/types';

const initialState = [];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SEARCH_FILTERED_ACTIVITIES:
            return action.payload;
        default:
            return state;
    }
};

export default reducer;
