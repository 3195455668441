import ExpandButton from 'components/custom/ExpandButton/ExpandButton';
import { Button, InputField } from 'dibk-design';
import { createRandomId, objectHasChanged } from 'helpers/dataHelpers';
import { useEffect, useRef, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { toast } from 'helpers/formHelpers';
import { useDeleteFacetsMutation } from 'store/slices/adminApiSlice';
import './StaticMetadataValues.scss';

function StaticMetadataValues({ filter, mutationHooks }) {
   const [expanded, setExpanded] = useState(false);
   const [staticMetadataFacets, setStaticMetadataFacets] = useState(filter.facets.map(facet => ({ ...facet, chosen: true })));
   const originalstaticMetadataFacets = useRef([]);
   const [addMutationTrigger] = mutationHooks.add();
   const [updateMutationTrigger] = mutationHooks.update();
   const [deleteFacets] = useDeleteFacetsMutation();

   useEffect(
      () => {
         const mappedData = filter.facets.map(value => ({ ...value, elementKey: createRandomId(), chosen: true }));
         setStaticMetadataFacets([...mappedData]);
         originalstaticMetadataFacets.current = [...mappedData];
      },
      [filter.facets]
   );

   function handleChange(event, index) {
      const { name, value } = event.target;

      const updated = staticMetadataFacets.map((filter, idx) => {
         return idx !== index ? filter : { ...filter, [name]: value };
      });

      setStaticMetadataFacets(updated);
   }

   function handleListSorted(sortedList) {
      sortedList.forEach((facet, index) => facet.orderNumber = index + 1);
      setStaticMetadataFacets(sortedList);
   }

   function addFacet() {
      setStaticMetadataFacets([...staticMetadataFacets, {
         id: createRandomId(),
         text: '',
         description: '',
         orderNumber: staticMetadataFacets.length,
         chosen: true,
         newFacet: true
      }]);
   }

   function deleteFacet(facet) {
      setStaticMetadataFacets(staticMetadataFacets.filter(fac => fac.id !== facet.id));
   }

   async function save() {
      handleListSorted(staticMetadataFacets);

      if (!objectHasChanged(originalstaticMetadataFacets.current, staticMetadataFacets)) {
         return;
      }

      const toAdd = staticMetadataFacets.filter(facet => !!facet.newFacet);
      const toUpdate = staticMetadataFacets.filter(facet => !facet.newFacet);
      const toDelete = originalstaticMetadataFacets.current
         .filter(facet => !staticMetadataFacets.some(fac => fac.id === facet.id))
         .map(facet => facet.id);

      try {
         await apiAdd(toAdd);
      } catch {
         toast.error('Kunne ikke legge til metadataverdier');
      }

      try {
         await apiUpdate(toUpdate);
      } catch {
         toast.error('Kunne ikke oppdatere metadataverdier');
      }

      try {
         await apiDelete(toDelete);
      } catch {
         toast.error('Kunne ikke slette metadataverdier');
      }
   }

   async function apiAdd(facets) {
      if (!facets.length) {
         return;
      }

      facets.forEach(facet => delete facet.newFacet);

      try {
         await addMutationTrigger(facets).unwrap();
         toast.success(`${facets.length} nye metadataverdier ble lagt til`);
      } catch {
         throw new Error();
      }
   }

   async function apiUpdate(facets) {
      if (!facets.length) {
         return;
      }

      try {
         await updateMutationTrigger(facets).unwrap();
         toast.success(`${facets.length} metadataverdier ble oppdatert`);
      } catch {
         throw new Error();
      }
   }

   async function apiDelete(facetIds) {
      if (!facetIds.length) {
         return;
      }

      try {
         await deleteFacets(facetIds).unwrap();
         toast.success(`${facetIds.length} metadataverdier ble slettet`);
      } catch {
         throw new Error();
      }
   }

   return (
      <div className={`filter ${expanded ? 'filter-expanded' : ''}`}>
         <ExpandButton onExpanded={setExpanded}>{filter.name} ({staticMetadataFacets.length})</ExpandButton>

         <div className="facets-container">
            {
               staticMetadataFacets.length ?
                  <div className="facets">
                     <div className="facets-table">
                        <div className="facets-header">
                           <div></div>
                           <div>Navn</div>
                           <div>Beskrivelse</div>
                           <div></div>
                        </div>
                        <ReactSortable list={staticMetadataFacets} setList={handleListSorted} handle=".handle">
                           {
                              staticMetadataFacets.map((facet, index) => {
                                 return (
                                    <div className="facet" key={`facet-${facet.id}`}>
                                       <div>
                                          <div className="handle"></div>
                                       </div>
                                       <div>
                                          <InputField
                                             elementKey={'name-' + index}
                                             id={'name-' + index}
                                             name="description"
                                             onChange={event => handleChange(event, index)}
                                             defaultValue={facet.description || ''}
                                          />
                                       </div>
                                       <div>
                                          <InputField
                                             elementKey={'description-' + index}
                                             id={'description-' + index}
                                             name="text"
                                             onChange={event => handleChange(event, index)}
                                             defaultValue={facet.text || ''}
                                          />
                                       </div>
                                       <div>
                                          {
                                             index === staticMetadataFacets.length - 1 ?
                                                <Button color="primary" size="small" onClick={addFacet}>Legg til</Button> :
                                                null
                                          }
                                          <Button color="secondary" size="small" onClick={() => deleteFacet(facet)}>Slett</Button>
                                       </div>
                                    </div>
                                 )
                              })
                           }
                        </ReactSortable>
                     </div>
                     <div>
                        {
                           objectHasChanged(originalstaticMetadataFacets.current, staticMetadataFacets) ?
                              <Button color="primary" size="small" onClick={save}>Lagre</Button>
                              : null
                        }
                     </div>
                  </div> :
                  <div>
                     <div className="mb-10">
                        {objectHasChanged(originalstaticMetadataFacets.current, staticMetadataFacets) ?
                           <Button color="primary" size="small" onClick={save}>Lagre</Button>
                           : null
                        }
                     </div>
                     <div>
                        <Button color="primary" size="small" onClick={addFacet}>Legg til</Button>
                     </div>
                  </div>
            }
         </div>
      </div>
   );
}

export default StaticMetadataValues;