// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

// Types
import { FETCH_PROCESSCATEGORIES } from "constants/types";

export const fetchProcessCategories = () => (dispatch) => {
    const apiUrl = `${getEnvironmentVariable("backendHost")}/checklist-api`;
    return fetch(apiUrl)
        .then((res) => res.json())
        .then((processCategories) => {
            processCategories
                ? dispatch({
                      type: FETCH_PROCESSCATEGORIES,
                      payload: processCategories
                  })
                : console.warn('No "ProcessCategories" in ' + apiUrl);
        });
};

export const getProcessCategoryByKey = (processCategoryKey) => (dispatch, getState) => {
    const processCategories = getState() && getState().processCategories ? getState().processCategories : null;
    const hasProcessCategories =
        processCategories && processCategories.filters && Object.keys(processCategories.filters).length;
    const requestedProcessCategory = hasProcessCategories && processCategories?.filters?.[processCategoryKey];
    return requestedProcessCategory;
};
