import { toast as toastr } from 'react-toastify';

const defaultOptions = {
   autoClose: 3000,
   pauseOnHover: false,
   draggable: false,
   className: 'checklist-toast'
};

export const toast = {
   success: (message, options = {}) => {
      toastr.success(message, { ...defaultOptions, ...options })
   },
   error: (message, options = {}) => {
      toastr.error(message, { ...defaultOptions, ...options })
   }
}