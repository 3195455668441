// Dependencies
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// DIBK Design
import { Header, Button, Lead } from "dibk-design";

// Helpers
import { classNameArrayToClassNameString } from "helpers/guiHelpers";

// Stylesheets
import wizardStyle from "components/routes/Wizard.module.scss";

const DibkHomeContent = ({ headerContent, checklistApiUrl }) => {
    // Redux store
    const selectedLanguage = useSelector((state) => state.selectedLanguage);

    return selectedLanguage === "nn" ? (
        <Fragment>
            <Header label="Veiviser" size={1}>
                {headerContent}
            </Header>
            <Lead>
                Skal du behandle ein byggjesøknad? Bruk sjekklistene når du skal sjekke kva føresegner som gjeld for
                søknaden.
            </Lead>
            <Header content="Slik bruker du sjekklistene i saksbehandlinga" size={2} />
            <p>
                Sjekklistene gir deg som byggjesaksbehandlar ei komplett liste over kva punkt kommunen skal vurdere i
                byggjesaker.
            </p>
            <p>Du får òg ei forklaring på korleis vurderinga skal gjerast.</p>
            <p>
                <i>Sjekklistene er baserte på gjeldande regelverk for byggjesaksbehandling</i>
            </p>
            <div className={classNameArrayToClassNameString([wizardStyle.buttonRow, wizardStyle.marginBottom])}>
                <Button type="button" color="primary">
                    <Link to="/checklist">Vel kva søknad du skal vurdere</Link>
                </Button>
            </div>
            <Lead>
                Sjekklistene kan òg brukast via vårt API.
                <br />
                <a href={checklistApiUrl}>Api for sjekklistene</a>
            </Lead>
        </Fragment>
    ) : (
        <Fragment>
            <Header label="Veiviser" size={1}>
                {headerContent}
            </Header>
            <Lead>
                Skal du behandle en byggesøknad? Bruk sjekklistene når du skal sjekke hvilke bestemmelser som gjelder
                for søknaden.
            </Lead>
            <Header content="Slik bruker du sjekklistene i saksbehandlingen" size={2} />
            <p>
                Sjekklistene gir deg som byggesaksbehandler en komplett liste over hvilke punkter kommunen skal vurdere
                i byggesaker.
            </p>
            <p>Du får også en forklaring på hvordan vurderingen skal gjøres.</p>
            <p>
                <i>Sjekklistene er basert på gjeldende regelverk for byggesaksbehandling</i>
            </p>
            <div className={classNameArrayToClassNameString([wizardStyle.buttonRow, wizardStyle.marginBottom])}>
                <Button type="button" color="primary">
                    <Link to="/checklist">Velg hvilken søknad du skal vurdere</Link>
                </Button>
            </div>
            <Lead>
                Sjekklistene kan også brukes via vårt API.
                <br />
                <a href={checklistApiUrl}>Api for sjekklistene</a>
            </Lead>
        </Fragment>
    );
};

export default DibkHomeContent;
