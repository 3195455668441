// Dependencies
import { useSelector } from "react-redux";

// DIBK Design
import { Accordion } from "dibk-design";

// Components
import ActivityListItem from "./ActivityList/ActivityListItem";

// Stylesheets
import style from "./ActivityList.module.scss";

function ActivityList({onValidationRulesSave}) {
    // Redux store
    const activities = useSelector((state) => state.searchFilteredValidationRulesActivities);

    function renderActivity(activity) {
        return (
            <div key={activity.activityId} className={style.marginBottomSmall}>
                <Accordion title={`${activity.activityReferenceId} - ${activity.activityName}`}>
                    <ActivityListItem activity={activity} onValidationRulesSave={onValidationRulesSave} />
                </Accordion>
            </div>
        );
    }

    function renderActivities(activities) {
        if (!activities?.length) {
            return "Ingen sjekkpunkt";
        } else {
            return activities.map((activity) => {
                return renderActivity(activity);
            });
        }
    }

    return <div className={style.marginTopLarge}>{renderActivities(activities)}</div>;
}

export default ActivityList;
