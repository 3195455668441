// Dependencies
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

// DIBK Design
import { RadioButtonList, RadioButtonListItem, Header } from "dibk-design";

// Actions
import { fetchProcessCategories } from "actions/ProcessCategoriesActions";

// Stylesheets
import style from "./ProcessCategorySection.module.scss";

const ProcessCategorySection = () => {
    const dispatch = useDispatch();

    // Redux store
    const processCategories = useSelector((state) => state.processCategories);
    const selectedProcessCategoryKey = useSelector((state) => state.selectedProcessCategoryKey);
    const location = useSelector((state) => state.router.location);

    // State
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        dispatch(fetchProcessCategories());
        if (redirect) {
            setRedirect(null);
        }
    }, [dispatch, redirect]);

    useEffect(() => {
        if (redirect) {
            setRedirect(null);
        }
        if (!!location?.state?.autoFocusOnSelectedRadioButton) {
            const selectedRadioButtonElement = getSelectedRadioButtonElement();
            if (selectedRadioButtonElement) {
                selectedRadioButtonElement.focus();
            }
        }
    }, [location, redirect]);


    function getSelectedRadioButtonElement() {
        const radioButtonElements = document.getElementsByName("processCategory");
        return Array.from(radioButtonElements).find((radioButtonElement) => {
            return radioButtonElement.checked;
        });
    }

    function handleOnSelectedProcessCategoryKeyChange(selectedProcessCategoryKey) {
        const url = `/checklist/${selectedProcessCategoryKey}`;
        setRedirect(url);
    }

    function renderProcessCategoryLinks(processCategories) {
        const hasProcessCategories =
            processCategories && processCategories.filters && Object.keys(processCategories.filters).length;
        const processCategoriesElement = hasProcessCategories
            ? Object.keys(processCategories.filters).map((processCategoryKey) => {
                  const processCategory = processCategories.filters[processCategoryKey];
                  return (
                      <RadioButtonListItem
                          id={`processCategory-${processCategoryKey}`}
                          name={`processCategory`}
                          onChange={() => handleOnSelectedProcessCategoryKeyChange(processCategoryKey)}
                          inputValue={processCategoryKey}
                          key={processCategoryKey}
                          checked={selectedProcessCategoryKey === processCategoryKey}
                          compact
                      >
                          {processCategory.name}
                      </RadioButtonListItem>
                  );
              })
            : [];
        return (
            <fieldset className={style.processCategorySection}>
                <Header content={processCategories.name} size={3} />
                <RadioButtonList compact>{processCategoriesElement}</RadioButtonList>
            </fieldset>
        );
    }

    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname: redirect,
                    state: {
                        autoFocusOnSelectedRadioButton: true
                    }
                }}
            />
        );
    }
    return processCategories ? renderProcessCategoryLinks(processCategories) : "";
};

export default ProcessCategorySection;
