// Dependencies
import { v4 as uuidv4 } from "uuid";

export function addTempIdsForValidationRuleIds(validationRulesVersions) {
    return validationRulesVersions.map((validationRulesVersion) => {
        return {
            ...validationRulesVersion,
            validationRuleIds: validationRulesVersion?.validationRuleIds?.length
                ? validationRulesVersion.validationRuleIds.map((validationRuleId) => {
                      return {
                          tempId: uuidv4(),
                          value: validationRuleId
                      };
                  })
                : []
        };
    });
}

export function removeTempIdsForValidationRuleIds(validationRulesVersions) {
    return validationRulesVersions.map((validationRulesVersion) => {
        return {
            ...validationRulesVersion,
            validationRuleIds: validationRulesVersion.validationRuleIds.map((validationRuleId) => {
                return validationRuleId.value;
            })
        };
    });
}
