import { FETCH_SELECTED_FACETS, UPDATE_SELECTED_FACETS_FROM_URL } from 'constants/types';

const initialState = {
    'Milestone': {},
    'ActivityType': {},
    'Category': {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SELECTED_FACETS:
      return action.payload;
    case UPDATE_SELECTED_FACETS_FROM_URL:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
