import { isRejectedWithValue } from '@reduxjs/toolkit'
import userManagerPromise from 'utils/userManager';

export const authMiddleware = _ => next => async action => {
   if (isRejectedWithValue(action) && action.payload.status === 401) {
      const userManager = await userManagerPromise;
      userManager.signinRedirect({ 'state': { pathname: window.location.pathname } });
   }

   return next(action);
}