// Components
import ActionListItem from "./ActionList/ActionListItem";

function ActionList({ actions, activityId, onValidationRulesSave }) {
    function renderActions(actions) {
        if (!actions?.length) {
            return "Ingen utfall";
        } else {
            return actions.map((action) => {
                return <ActionListItem key={action.actionId} action={action} activityId={activityId} onValidationRulesSave={onValidationRulesSave} />;
            });
        }
    }
    return renderActions(actions);
}

export default ActionList;
