export const convertInputDateToUrlString = (inputDate) => {
    if (!inputDate) {
        return "";
    }
    const date = new Date(inputDate);
    return date && date instanceof Date ? date.toISOString().slice(0, 10) : "";
};

export const convertFromInputDateToTimestamp = (inputDate) => {
    return inputDate?.length ? new Date(inputDate).toISOString() : null;
};

export const convertFromTimestampToInputDate = (timestamp) => {
    if (!timestamp) {
        return null;
    }
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${year}-${month}-${day}`;
};



export const convertFromTimestampToText = (timestamp) => {
    if (!timestamp) {
        return null;
    }
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${day}.${month}.${year}`;
};
